import React, { useEffect, useRef, useState } from 'react';
import { Platform, Pressable, ScrollView, Text, TextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import OutsidePressHandler from 'react-native-outside-press';
import {
  Article,
  Event,
  GetArticlesParams,
  GetEventsParams,
  Pagination as IPagination,
  Tag as TagInterface,
} from '../../client/interfaces';
import useDebounce from '../../hooks/useDebounce';
import { TagVariant } from '../../resources/interfaces';
import { isMobile, isNative, isSmallMobile, isTablet, isWeb } from '../../utils/responsive';
import svgIcons from '../../assets';
import { Dropdown, Icon, ResultCounter, Tag } from '../index';
import { cln } from '../../utils/classnames';
import { useResize } from '../../utils/resize';
import { useTags } from '../../contexts/Tag/TagContext';
import { ScreenName } from '../../navigation/types';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  getPostsParams: GetEventsParams | GetArticlesParams;
  setGetPostsParams:
    | React.Dispatch<React.SetStateAction<GetArticlesParams>>
    | React.Dispatch<React.SetStateAction<GetEventsParams>>;
  resetInfiniteScroll: () => void;
  allPosts?: Article[] | Event[];
  screen?: ScreenName.ArticlesScreen | ScreenName.EventsScreen;
  pagination?: IPagination;
}

function isGetEventsParams(params: GetEventsParams | GetArticlesParams): params is GetEventsParams {
  return (params as GetEventsParams).isPast !== undefined;
}

const PostFilter: React.FC<Props> = ({
  getPostsParams,
  setGetPostsParams,
  resetInfiniteScroll,
  allPosts,
  screen,
  pagination,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { filterTags, userTags } = useTags();
  const [isFavoritesActive, setIsFavoritesActive] = useState<boolean>(false);
  const { isWidthBelowSmall, isWidthBelowMedium } = useResize();

  // Search input field states
  const [search, setSearch] = useState<string>(getPostsParams?.search);
  const [searchActive, setSearchActive] = useState(false);

  // WEB DROPDOWN OUTSIDE CLICK
  const { visible, setVisible, reference } = useOnClickOutside(false);

  const debouncedSearch = useDebounce(search);
  const searchInputRef = useRef(null);

  // Dropdown input field states
  const [dropdownTags, setDropdownTags] = useState([]);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [tagSearch, setTagSearch] = useState<string>('');

  // Use refs for clicking outside
  // Dropdown ref for bluring the input in the handleBlur function
  const dropdownInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const [firstInputKey, setFirstInputKey] = useState(0);
  const [secondInputKey, setSecondInputKey] = useState(0);
  //Android padding adding workaround
  const handleReRender = () => {
    if ((firstInputKey === 0 || secondInputKey === 0) && Platform.OS === 'android') {
      setFirstInputKey((k) => k + 1);
      setSecondInputKey((k) => k + 1);
    }
  };

  // Dropdown filtering based on text input
  useEffect(() => {
    if (tagSearch) {
      const filteringTagsUpdated = filterTags.filter((tag) => {
        return (
          tag.translationValue.toLowerCase().includes(tagSearch.toLowerCase()) &&
          !getPostsParams.tags.includes(String(tag.id))
        );
      });
      setDropdownTags(filteringTagsUpdated);
    } else {
      const updatedTags = filterTags.filter((tag) => !getPostsParams.tags.includes(String(tag.id)));
      setDropdownTags(updatedTags);
    }
  }, [tagSearch, filterTags]);

  //Removing tags from chosen tags
  function removeTag(tag: TagInterface) {
    setGetPostsParams((prevState) => ({
      ...prevState,
      tags: prevState.tags.filter((tagId) => {
        return tag.id !== Number(tagId);
      }),
    }));
  }

  function emptyFilters() {
    setIsFavoritesActive(false);
    handleBlur();
    setGetPostsParams((prevState) => ({
      ...prevState,
      search: '',
      tags: [],
      isBookmarked: false,
      ...(isGetEventsParams(getPostsParams) ? { isPast: false } : {}),
    }));
    setSearch('');
  }

  // Updating the list of tags we can choose from when chosen tags change so either removing from the list or adding to the list
  useEffect(() => {
    const updatedArray = filterTags.filter(
      (item) => !getPostsParams?.tags.includes(String(item.id)),
    );
    setDropdownTags(updatedArray);
  }, [getPostsParams?.tags]);

  useEffect(() => {
    resetInfiniteScroll();
    setGetPostsParams((prev) => ({ ...prev, search: debouncedSearch }));
  }, [debouncedSearch]);

  // When pressing the favorite we clean chosen tags, fetchTags, set userTags for the filter
  const onFavoritesPress = () => {
    if (isFavoritesActive) {
      setGetPostsParams((prevState) => ({ ...prevState, tags: [] }));
    } else {
      setGetPostsParams((prevState) => ({
        ...prevState,
        tags: userTags.map((tag) => {
          return String(tag.id);
        }),
      }));
    }

    setIsFavoritesActive((prev) => !prev);
  };

  const onBookmarkedPress = () => {
    setGetPostsParams((prev) => ({
      ...prev,
      isBookmarked: !getPostsParams?.isBookmarked,
      page: 1,
    }));
  };

  const onPastEventsPress = () => {
    setGetPostsParams((prev: GetEventsParams) => ({
      ...prev,
      isPast: !(getPostsParams as GetEventsParams)?.isPast,
      page: 1,
    }));
  };

  function handleBlur() {
    dropdownInputRef.current.blur();
    searchInputRef.current.blur();
    if (dropdownActive && isNative) {
      setDropdownActive(false);
    }
    if (visible && !isNative) {
      setVisible(false);
    }

    setSearchActive(false);
  }

  function handleFocus() {
    if (!dropdownActive && isNative) {
      setDropdownActive(true);
    }
    if (!visible && !isNative) {
      setVisible(true);
    }
    dropdownInputRef.current.focus();
  }

  const bookmarkedText =
    screen === ScreenName.ArticlesScreen ? t('articles:bookmarked') : t('events:bookmarked');

  return (
    <View className="mb-6 md:mb-10 z-50">
      <View className="flex-row mb-3 justify-start sm:justify-end">
        <Tag
          onPress={() => {
            onBookmarkedPress();
            handleBlur();
          }}
          variant={TagVariant.IconTag}
          iconName={'bookmarkBorder'}
          isFilled={getPostsParams?.isBookmarked}
          margin="mr-3"
        >
          {(isTablet || (!isWidthBelowSmall && !isMobile)) && bookmarkedText}
        </Tag>

        <Tag
          onPress={() => {
            onFavoritesPress();
            handleBlur();
          }}
          variant={TagVariant.IconTag}
          iconName={'starIcon'}
          isFilled={isFavoritesActive}
        >
          {(isTablet || (!isWidthBelowSmall && !isMobile)) && t('articles:followed_tags')}
        </Tag>
        {screen === ScreenName.EventsScreen && (
          <Tag
            onPress={() => {
              onPastEventsPress();
              handleBlur();
            }}
            variant={TagVariant.IconTag}
            iconName={'pastIcon'}
            isFilled={(getPostsParams as GetEventsParams)?.isPast}
            margin="ml-3"
          >
            {(isTablet || (!isWidthBelowSmall && !isMobile)) && t('events:past_events')}
          </Tag>
        )}
      </View>

      <View className="flex-column" style={{ columnGap: 16, rowGap: 8 }}>
        <View
          className="flex-column sm:flex-row relative z-30"
          style={{ columnGap: 16, rowGap: 16 }}
        >
          <OutsidePressHandler
            onOutsidePress={() => {
              setSearchActive(false);
              searchInputRef.current.blur();
            }}
            style={{ flex: isTablet || isWeb ? 1 : null }}
          >
            <View className={cln('flex-row items-center h-12', (isWeb || isTablet) && 'flex-1')}>
              <View className="absolute ml-4 z-20">
                <Icon
                  icon={svgIcons.searchIcon}
                  mobileSize={{ width: 24, height: 24 }}
                  webSize={{ width: 24, height: 24 }}
                  inverted={searchActive}
                />
              </View>

              <TextInput
                onLayout={handleReRender}
                key={firstInputKey}
                ref={searchInputRef}
                style={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  isWeb && { outline: 'none' }
                }
                placeholderTextColor={'#888888'}
                className={cln(
                  searchActive
                    ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
                    : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
                  'h-12 border-[1px] flex-1 pl-[52px] pr-3 border-neutral-900 dark:border-neutral-200',
                )}
                focusable={false}
                onChangeText={setSearch}
                onFocus={() => {
                  setSearchActive(true);
                  setDropdownActive(false);
                }}
                onBlur={() => setSearchActive(false)}
                value={search}
                keyboardType="ascii-capable"
                placeholder={t('common:search')}
                blurOnSubmit={true}
                onSubmitEditing={() => setSearchActive(false)}
              />
            </View>
          </OutsidePressHandler>
          {/*.......*/}
          {/*DROPDOWN INPUT AND CONTAINER WITH TAGS*/}
          {/*.......*/}
          <View
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className={cln('flex-row items-center h-12', (isWeb || isTablet) && 'flex-1')}
            // Reference, for web we need to use the useOnclickOutside
            ref={reference}
          >
            <TextInput
              onLayout={handleReRender}
              key={secondInputKey}
              style={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isWeb && { outline: 'none' }
              }
              ref={dropdownInputRef}
              placeholderTextColor="#888888"
              className={cln(
                dropdownActive || visible
                  ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
                  : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
                'h-12 border-[1px] flex-1 px-3 border-neutral-900 dark:border-neutral-200',
              )}
              onFocus={handleFocus}
              onChangeText={setTagSearch}
              value={tagSearch}
              keyboardType="ascii-capable"
              placeholder={t('articles:filter_tags_placeholder')}
              blurOnSubmit={true}
            />
            {/*.....*/}
            {/*CHEVRON ICON BUTTON*/}
            {/*.....*/}
            {isNative && (
              <View
                className="absolute z-20 right-[8px]"
                style={{
                  display: dropdownActive && isNative ? 'none' : 'flex',
                }}
              >
                <Pressable
                  onPress={() => {
                    if (!dropdownActive) {
                      handleFocus();
                    }
                  }}
                  className="py-2 px-2"
                >
                  <Icon
                    icon={dropdownActive ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                    mobileSize={{ width: 24, height: 24 }}
                    webSize={{ width: 24, height: 24 }}
                    inverted={dropdownActive}
                  />
                </Pressable>
              </View>
            )}
            {/*NATIVE OUTSIDE PRESS HANDLER*/}
            {isNative && (
              <OutsidePressHandler
                onOutsidePress={() => {
                  handleBlur();
                }}
                style={{
                  display: dropdownActive ? 'flex' : 'none',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <View className="absolute z-20 right-[8px] top-[2px]">
                  <Pressable onPress={handleBlur} className="py-2 px-2">
                    <Icon
                      icon={dropdownActive ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                      mobileSize={{ width: 24, height: 24 }}
                      webSize={{ width: 24, height: 24 }}
                      inverted={dropdownActive}
                    />
                  </Pressable>
                </View>
                {dropdownActive && (
                  <Dropdown
                    onPress={(option) => {
                      setGetPostsParams((prevState) => ({
                        ...prevState,
                        tags: isFavoritesActive
                          ? [String(option.id)]
                          : [...prevState.tags, String(option.id)],
                      }));
                      setIsFavoritesActive(false);
                      handleBlur();
                      setTagSearch('');
                    }}
                    dropdownTags={dropdownTags}
                    dropdownRef={dropdownRef}
                  />
                )}
              </OutsidePressHandler>
            )}
            {/*.......*/}
            {/*WEB CHEVRON PRESSABLE */}
            {/*.......*/}
            {!isNative && (
              <View className="absolute z-20 right-[8px]">
                <Pressable
                  onPress={() => {
                    if (visible) {
                      handleBlur();
                    } else {
                      handleFocus();
                    }
                  }}
                  className="py-2 px-2"
                >
                  <Icon
                    icon={visible ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                    mobileSize={{ width: 24, height: 24 }}
                    webSize={{ width: 24, height: 24 }}
                    inverted={visible}
                  />
                </Pressable>
              </View>
            )}
            {/*WEB DROPDOWN*/}
            {visible && !isNative && (
              <Dropdown
                onPress={(option) => {
                  setGetPostsParams((prevState) => ({
                    ...prevState,
                    tags: isFavoritesActive
                      ? [String(option.id)]
                      : [...prevState.tags, String(option.id)],
                  }));
                  setIsFavoritesActive(false);
                  handleBlur();
                  setTagSearch('');
                }}
                dropdownTags={dropdownTags}
                dropdownRef={dropdownRef}
              />
            )}
          </View>
        </View>

        <View
          className="w-full justify-between flex-nowrap"
          style={{
            flexDirection: isTablet ? 'row' : isMobile || isWidthBelowMedium ? 'column' : 'row',
          }}
        >
          {isTablet || isWeb ? (
            //   ON WEB OTHER LAYOUT
            <View
              className="flex-row flex-wrap z-10"
              style={{
                rowGap: 8,
                columnGap: 8,
                width: !isTablet && (isWidthBelowMedium || isMobile) ? '100%' : '75%',
                display: isFavoritesActive ? 'none' : 'flex',
                marginBottom: getPostsParams?.tags?.length >= 1 ? 12 : 0,
              }}
            >
              {getPostsParams?.tags &&
                getPostsParams?.tags?.map((tagId) => {
                  const tag = filterTags.find((t) => t.id === Number(tagId));
                  return (
                    <Tag
                      key={tag.id}
                      variant={TagVariant.Selected}
                      onPressRemove={() => {
                        removeTag(tag);
                      }}
                    >
                      {tag?.translationValue}
                    </Tag>
                  );
                })}
            </View>
          ) : (
            <View
              style={{
                maxHeight: isSmallMobile ? 188 : 288,
                marginBottom: 8,
                paddingBottom:
                  getPostsParams?.tags?.length > 0 && isMobile && !isFavoritesActive ? 8 : 0,
              }}
            >
              <ScrollView>
                <View
                  className="flex-row flex-wrap z-10"
                  style={{
                    rowGap: 8,
                    columnGap: 8,
                    width: '100%',
                    display: isFavoritesActive ? 'none' : 'flex',
                  }}
                >
                  {getPostsParams?.tags &&
                    getPostsParams?.tags?.map((tagId) => {
                      const tag = filterTags.find((t) => t.id === Number(tagId));
                      return (
                        <Tag
                          key={tag.id}
                          variant={TagVariant.Selected}
                          onPressRemove={() => {
                            removeTag(tag);
                          }}
                        >
                          {tag.translationValue}
                        </Tag>
                      );
                    })}
                </View>
              </ScrollView>
            </View>
          )}

          <View
            className="justify-start items-start flex-col-reverse ml-auto"
            style={{
              width:
                isTablet || (isWeb && !isMobile)
                  ? '25%'
                  : isWidthBelowMedium || isMobile
                    ? '40%'
                    : '100%',
            }}
          >
            {/* REMOVE ALL BUTTON */}
            {(getPostsParams?.tags?.length >= 1 ||
              !!search ||
              getPostsParams.isBookmarked ||
              (isGetEventsParams(getPostsParams) && getPostsParams.isPast)) && (
              <Pressable
                className="justify-center items-center"
                onPress={emptyFilters}
                style={{
                  alignSelf: 'flex-end',
                  marginBottom: 0,
                }}
              >
                <Text className="text-primary-600 dark:text-primary-500 text-body font-[sans-400] py-2">
                  {t('articles:remove_search')}
                </Text>
              </Pressable>
            )}
            {isTablet || !isMobile ? (
              <ResultCounter
                pagination={pagination}
                classNames={cln(
                  isTablet ? 'mb-0' : 'mb-0',
                  isTablet ? 'self-end' : isMobile ? 'self-start' : 'self-end',
                )}
              />
            ) : (
              <></>
            )}
            {/*HOW MANY ARTICLES CONTAINER*/}
            {!isTablet && isMobile && (
              <View className="flex-row self-end">
                <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-700] mr-1.5">
                  {allPosts.length}
                </Text>
                <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-400]">
                  {t('articles:results')}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PostFilter;
