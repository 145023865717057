import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ScrollView, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../components/Layout';
import svgIcons, { SvgIcon } from '../../assets/index';
import { IconCard } from '../../components';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { handleLinkPress } from '../../utils';
import { isMobile, isNative, isTablet, isWidthBelowMedium } from '../../utils/responsive';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { cln } from '../../utils/classnames';

// TODO: multitenancy-nál tegyük dinamikussá
enum IconCardType {
  e_learning = 'e_learning',
  data_vizart = 'data_vizart',
  activities = 'activities',
  course_insight = 'course_insight',
  course_insight_admin = 'course_insight_admin',
  power_bi = 'power_bi',
  mome_run = 'mome_run',
  canteen = 'canteen',
  neptun = 'neptun',
  bug_report = 'bug_report',
  campus_helpdesk = 'campus_helpdesk',
  nexon = 'nexon',
  info_bar = 'info_bar',
}

interface IconCardInterface {
  type: IconCardType;
  icon: SvgIcon;
  onPress?: () => void;
  active: boolean;
  isOwn: boolean;
}

const PlatformsScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.PlatformsScreen>
> = () => {
  const { t } = useTranslation();
  const { isStudent, isEmployee } = useAuth();

  const [iconCards, setIconCards] = useState<IconCardInterface[]>([]);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [cardWidth, setCardWidth] = useState<number>(0);

  const flatListRef = useRef<FlatList>(null);

  const links: { [key in IconCardType]: string } = {
    [IconCardType.e_learning]: 'https://elearning.mome.hu/',
    [IconCardType.data_vizart]: 'http://data-vizart.mome.hu:8080',
    [IconCardType.activities]: 'https://tevekenyseg.mome.hu/',
    [IconCardType.course_insight]: 'https://omhv.mome.hu/',
    [IconCardType.course_insight_admin]: 'https://omhv.mome.hu/admin/',
    [IconCardType.power_bi]:
      'https://teams.microsoft.com/l/team/19%3Ann7mtSvZARlWw3150R0KdTKQNeJzT2B1rhtO_jkRLCY1%40thread.tacv2/conversations?groupId=01992206-c9b5-46bd-ad5f-faaa7ad74d53&tenantId=b9159e33-1e3c-4cd2-b02f-bb2a2f4df561',
    [IconCardType.mome_run]: 'https://run.mome.hu/',
    [IconCardType.canteen]: 'https://mome.hu/hu/kantin',
    [IconCardType.neptun]: 'https://host.sdakft.hu/momehw/login.aspx',
    [IconCardType.bug_report]:
      'https://forms.clickup.com/24417130/f/q94va-13865/UF1ANHWVMM7PIEJNYD',
    [IconCardType.campus_helpdesk]: 'https://mome.hu/hu/campus-helpdesk',
    [IconCardType.nexon]: 'https://nexon.mome.hu/',
    [IconCardType.info_bar]: 'https://momehu.sharepoint.com/sites/MOME',
  };

  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(Math.floor(width));
  };

  useEffect(() => {
    setCardWidth(parentWidth / 2 - 38);
  }, [parentWidth]);

  const updateIconCards = () => {
    const allIconCards: IconCardInterface[] = [
      {
        type: IconCardType.bug_report,
        icon: svgIcons.bugIcon,
        active: true,
        isOwn: true,
      },
      {
        type: IconCardType.data_vizart,
        icon: svgIcons.constellationIcon,
        active: true,
        isOwn: true,
      },
      {
        type: IconCardType.e_learning,
        icon: svgIcons.schoolIcon,
        active: false,
        isOwn: true,
      },
      {
        type: IconCardType.mome_run,
        icon: svgIcons.momeRunIcon,
        active: true,
        isOwn: true,
      },
      {
        type: IconCardType.course_insight,
        icon: svgIcons.girlCommentIcon,
        active: isStudent,
        isOwn: true,
      },
      {
        type: IconCardType.course_insight_admin,
        icon: svgIcons.boySettingsIcon,
        active: isEmployee,
        isOwn: true,
      },
      {
        type: IconCardType.activities,
        icon: svgIcons.fileIcon,
        active: true,
        isOwn: true,
      },
      {
        type: IconCardType.campus_helpdesk,
        icon: svgIcons.helpIcon,
        active: true,
        isOwn: false,
      },
      {
        type: IconCardType.canteen,
        icon: svgIcons.canteenIcon,
        active: true,
        isOwn: false,
      },
      {
        type: IconCardType.neptun,
        icon: svgIcons.neptunIcon,
        active: isStudent,
        isOwn: false,
      },
      {
        type: IconCardType.nexon,
        icon: svgIcons.nexonIcon,
        active: isEmployee,
        isOwn: false,
      },
      {
        type: IconCardType.power_bi,
        icon: svgIcons.chartsIcon,
        active: isEmployee,
        isOwn: false,
      },
      {
        type: IconCardType.info_bar,
        icon: svgIcons.infoIcon,
        active: isEmployee,
        isOwn: false,
      },
    ];
    setIconCards(allIconCards.filter((card) => card.active));
  };

  useEffect(() => {
    updateIconCards();
  }, [isStudent, isEmployee]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        flatListRef?.current?.scrollToOffset({ offset: 0 });
      };
    }, []),
  );

  const renderIconCards = (iconCards) => {
    return iconCards.map((item, index) => {
      return (
        <IconCard
          key={index}
          icon={item.icon}
          title={t(`platforms:${item.type}`)}
          onPress={() => (item.onPress ? item.onPress() : handleLinkPress(links[item.type]))}
          cardWidth={cardWidth}
        />
      );
    });
  };

  const ownApps = renderIconCards(iconCards.filter((item) => item.isOwn));
  const otherApps = renderIconCards(iconCards.filter((item) => !item.isOwn));

  return (
    <Layout
      title={!isMobile && t('navigation:platforms')}
      isScrollView={!isMobile}
      titleMarginBottom={false}
      isListScreen={true}
    >
      <View
        style={{
          paddingHorizontal: isMobile ? 30 : 0,
        }}
      >
        <Text
          className={cln(
            'text-neutral-950 dark:text-neutral-50 relative font-[sans-400]',
            !isTablet && isMobile ? 'text-platformSubtitleMobile' : 'text-lead mt-2',
          )}
        >
          {t('platforms:subtitle')}
        </Text>
      </View>

      <ScrollView
        className={cln(!isTablet && isMobile ? 'mt-6' : 'mt-10')}
        onLayout={handleLayout}
        showsVerticalScrollIndicator={true}
        scrollIndicatorInsets={{ right: 10 }}
        overScrollMode="never"
        contentContainerStyle={{ paddingHorizontal: isMobile ? 30 : 0 }}
      >
        <View
          className={cln(
            isMobile ? 'flex-column' : 'flex-column xl:flex-row xl:justify-between',
            'flex-wrap',
          )}
        >
          <View className={cln(isMobile ? 'w-full' : 'w-full xl:max-w-[648px] lg:mr-[64px]')}>
            <Text
              className={cln(
                'text-neutral-930 dark:text-neutral-50 font-[sans-600] text-lead sm:text-modalTitle mb-3 md:mb-6',
              )}
            >
              {t('platforms:own_apps')}
            </Text>
            <View
              className="flex-row flex-wrap"
              style={{
                gap: !isTablet && isMobile ? 16 : 24,
                paddingBottom: !isTablet && isMobile ? 24 : 40,
              }}
            >
              {ownApps}
            </View>
          </View>
          <View className={cln(isMobile ? 'w-full' : 'w-full xl:max-w-[424px]')}>
            <Text
              className={cln(
                'text-neutral-930 dark:text-neutral-50 font-[sans-600] text-lead sm:text-modalTitle mb-3 md:mb-6',
              )}
            >
              {t('platforms:other_apps')}
            </Text>
            <View
              className="flex-row flex-wrap"
              style={{
                gap: !isTablet && isMobile ? 16 : 24,
                paddingBottom: !isTablet && isMobile ? 24 : 40,
              }}
            >
              {otherApps}
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default PlatformsScreen;
