import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import client from '../../client/client';
import { Module, ModuleKey, ModulesToRoles, Role } from '../../client/interfaces';
import { useMessage } from '../Messages/MessageContext';
import useDebounce from '../../hooks/useDebounce';
import { EventSection, FeedSection, TodaySection } from '../../components';

interface ModuleContext {
  modules: Module[];
  setModules: Dispatch<SetStateAction<Module[]>>;
  modulesToRoles: ModulesToRoles;
  fetchModules: () => Promise<void>;
  isModuleActive: (key: ModuleKey) => boolean;
}

const modulesToRoles: ModulesToRoles = {
  [ModuleKey.feed]: Role.publisher,
  [ModuleKey.today]: null,
  [ModuleKey.event]: Role.publisher,
};

const Context = createContext<ModuleContext>({
  modules: [],
  modulesToRoles,
  setModules: () => {
    return [];
  },

  fetchModules: async () => {
    return;
  },
  isModuleActive: () => {
    return false;
  },
});

const modulesDebounceMs = 2.5 * 1000;

export const useModules = () => useContext(Context);

export const ModuleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setMessage } = useMessage();

  const [modules, setModules] = useState<Module[]>([]);
  const [lastSavedModuleOrder, setLastSavedModuleOrder] = useState<Module[]>([]);
  const debouncedModules = useDebounce(modules, modulesDebounceMs);

  const moduleComponents: { [key in ModuleKey]: FC } = {
    [ModuleKey.feed]: FeedSection,
    [ModuleKey.today]: TodaySection,
    [ModuleKey.event]: EventSection,
  };

  useEffect(() => {
    const stateChanged = !_.isEqual(debouncedModules, lastSavedModuleOrder);
    const neitherIsEmpty = lastSavedModuleOrder.length > 0 && debouncedModules.length > 0;
    const shouldUpdateOrder = stateChanged && neitherIsEmpty;

    if (shouldUpdateOrder) {
      setModuleOrder();
    }
  }, [debouncedModules]);

  const fetchModules = useCallback(async () => {
    try {
      const modulesResponse = await client.getModules();

      const modulesWithComponents = modulesResponse
        .filter((module) => {
          return moduleComponents[module.key];
        })
        .map((module) => {
          return { ...module, component: moduleComponents[module.key] };
        });
      setModules(modulesWithComponents);
      setLastSavedModuleOrder(modulesResponse);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  }, []);

  const setModuleOrder = async () => {
    try {
      await client.changeModulesOrder({ moduleIds: debouncedModules.map((m) => m.id) });
      setLastSavedModuleOrder(debouncedModules);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  const isModuleActive = (key: ModuleKey) => {
    return modules.some((module) => module.key === key);
  };

  const value: ModuleContext = useMemo(
    () => ({
      modules: modules || [],
      setModules: setModules,
      modulesToRoles,
      fetchModules,
      isModuleActive,
    }),
    [modules, fetchModules, isModuleActive],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
