import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { EventProvider } from 'react-native-outside-press';
import { createStackNavigator } from '@react-navigation/stack';
import {
  AdminArticleEditScreen,
  AdminEventEditScreen,
  AdminExtremeEventEditScreen,
  ArticleScreen,
  ErrorScreen,
  EventScreen,
  FavoritesScreen,
  HomeScreen,
  OnboardingScreen,
  SplashScreen,
} from '../screens';
import { useModules } from '../contexts/Module/ModuleContext';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { isMobile } from '../utils/responsive';
import { StackHeader } from '../components';
import navigationConfig from './navigationConfig';
import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';
import { ScreenName, StackName, TabNavigationEventMap, TabNavigationOptions } from './types';
import {
  AdminStackNavigator,
  ArticlesStackNavigator,
  EventsStackNavigator,
  HeaderStackNavigator,
  PlatformsStackNavigator,
  SettingsStackNavigator,
} from './stackNavigators';

let mainTabProps;

type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions;

const MainTabNavigation = ({ initialRouteName, children, screenOptions }: Props) => {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const menuProps = {
    state,
    navigation,
    descriptors,
  };

  mainTabProps = menuProps;

  return (
    <NavigationContent>
      <EventProvider>
        <View style={[{ flex: 1 }]}>
          {state.routes.map((route, i) => {
            return (
              <View
                key={route.key}
                style={[StyleSheet.absoluteFill, { display: i === state.index ? 'flex' : 'none' }]}
              >
                {descriptors[route.key].render()}
              </View>
            );
          })}
        </View>
        {!state.routes[state.index].name.includes('onboarding') && !isMobile && (
          <ProfileMenu {...menuProps} />
        )}
        {!state.routes[state.index].name.includes('onboarding') && <MainMenu {...menuProps} />}
      </EventProvider>
    </NavigationContent>
  );
};

const bottomTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof MainTabNavigation
>(MainTabNavigation);

const Tab = bottomTabNavigator();

const HomeStack = createStackNavigator();

function HomeStackNavigator() {
  const { t } = useTranslation();
  return (
    <HomeStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        headerShown: false,
      }}
    >
      <HomeStack.Screen
        name={ScreenName.HomeStackScreen}
        component={HomeScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <ProfileMenu stackNavigation={navigation} {...mainTabProps} />;
            },
            title: t('navigation:home'),
          };
        }}
      />
      <HomeStack.Screen name={StackName.HeaderStack} component={HeaderStackNavigator} />
      <HomeStack.Screen
        name={ScreenName.ArticleScreen}
        component={ArticleScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} />;
            },
          };
        }}
      />
      <HomeStack.Screen
        name={ScreenName.EventScreen}
        component={EventScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} />;
            },
          };
        }}
      />
    </HomeStack.Navigator>
  );
}

const TabNavigator = () => {
  const { t } = useTranslation();
  const { isModuleActive } = useModules();
  const { me, isAdmin } = useAuth();

  const screenComponents = {
    [ScreenName.HomeScreen]: HomeStackNavigator,
    [ScreenName.ArticlesScreen]: ArticlesStackNavigator,
    [ScreenName.EventsScreen]: EventsStackNavigator,
    [ScreenName.PlatformsScreen]: PlatformsStackNavigator,
    [ScreenName.SettingsScreen]: SettingsStackNavigator,
    [ScreenName.FavoritesScreen]: FavoritesScreen,
    [ScreenName.OnboardingScreen]: OnboardingScreen,
    [ScreenName.AdminIndexScreen]: AdminStackNavigator,
    [ScreenName.AdminArticleEditScreen]: AdminArticleEditScreen,
    [ScreenName.AdminExtremeEventEditScreen]: AdminExtremeEventEditScreen,
    [ScreenName.AdminEventEditScreen]: AdminEventEditScreen,
    [ScreenName.ErrorScreen]: ErrorScreen,
    [ScreenName.NotFound]: ErrorScreen,
    [ScreenName.EventScreen]: EventScreen,
    [ScreenName.SplashScreen]: SplashScreen,
  };

  return (
    <Tab.Navigator
      initialRouteName={me?.isOnboarded ? 'HomeScreen' : 'OnboardingScreen'}
      backBehavior={'order'}
    >
      {me?.isOnboarded ? (
        navigationConfig.map((screen) => {
          let isActive = true;

          // Check if module is activated
          if (screen?.moduleKey && !isModuleActive(screen.moduleKey)) {
            isActive = false;
          }

          // TODO: itt miért csak az AdminIndex van?
          // Check if user is admin
          if (screen.name === ScreenName.AdminIndexScreen && (!isAdmin || isMobile)) {
            isActive = false;
          }

          if (isActive && screen.isTab) {
            return (
              <Tab.Screen
                key={screen.name}
                name={screen.name}
                component={screenComponents[screen.name]}
                options={{
                  title: t(screen.translation),
                }}
              />
            );
          }
        })
      ) : !me ? (
        <Tab.Screen
          key={ScreenName.ErrorScreen}
          name={ScreenName.ErrorScreen}
          component={ErrorScreen}
        />
      ) : (
        <Tab.Screen
          key={ScreenName.OnboardingScreen}
          name={ScreenName.OnboardingScreen}
          component={OnboardingScreen}
          options={{
            title: t('navigation:onboarding'),
          }}
        />
      )}
    </Tab.Navigator>
  );
};
export default TabNavigator;
