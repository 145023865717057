import React, { createContext, useState } from 'react';

import { Event, GetEventsParams, Order, PostOrderBy } from '../../client/interfaces';

interface EventsParamsContextType {
  getEventsParams: GetEventsParams;
  setGetEventsParams: React.Dispatch<React.SetStateAction<GetEventsParams>>;
  nextPageIdentifier: number;
  setNextPageIdentifier: React.Dispatch<React.SetStateAction<number>>;
  allEvents: Event[];
  setAllEvents: React.Dispatch<React.SetStateAction<Event[]>>;
  resetInfiniteScroll: () => void;
  emptyGetEventsParams: GetEventsParams;
  emptyEventsFilter: () => void;
}

const emptyGetEventsParams: GetEventsParams = {
  orderBy: PostOrderBy.publishedAtUtc,
  order: Order.DESC,
  page: 1,
  perPage: 9,
  search: '',
  tags: [],
  isBookmarked: false,
  isPast: false,
};

const EventsParamsContext = createContext<EventsParamsContextType>({
  getEventsParams: emptyGetEventsParams,
  setGetEventsParams: () => {},
  nextPageIdentifier: 0,
  setNextPageIdentifier: () => {},
  allEvents: [],
  setAllEvents: () => {},
  resetInfiniteScroll: () => {},
  emptyGetEventsParams,
  emptyEventsFilter: () => {},
});

export const EventsProvider = ({ children }) => {
  const [getEventsParams, setGetEventsParams] = useState({ ...emptyGetEventsParams });
  const [nextPageIdentifier, setNextPageIdentifier] = useState<number>(0);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const resetInfiniteScroll = () => {
    setNextPageIdentifier(0);
  };

  function emptyEventsFilter() {
    setGetEventsParams((prevState) => ({
      ...prevState,
      tags: [],
      isBookmarked: false,
      search: '',
      isPast: false,
    }));
  }

  return (
    <EventsParamsContext.Provider
      value={{
        emptyGetEventsParams,
        getEventsParams,
        setGetEventsParams,
        nextPageIdentifier,
        setNextPageIdentifier,
        allEvents,
        setAllEvents,
        resetInfiniteScroll,
        emptyEventsFilter,
      }}
    >
      {children}
    </EventsParamsContext.Provider>
  );
};

export { EventsParamsContext };
