import React from 'react';
import { Text, Pressable, View, GestureResponderEvent } from 'react-native';
import { useColorScheme } from 'nativewind';
import { isNative } from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons from '../../assets';

interface Props {
  sectionTitle: string;
  handleDrag?: (e: React.MouseEvent<HTMLButtonElement> | GestureResponderEvent) => void;
  draggable?: boolean;
  onDragStart?: (e: React.DragEvent) => void;
  onDragEnter?: (e: React.DragEvent) => void;
  onDragOver?: (e: React.DragEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  containerStyle?: React.CSSProperties;
  handleUpPress?: (e: React.MouseEvent<HTMLButtonElement> | GestureResponderEvent) => void;
  handleDownPress?: (e: React.MouseEvent<HTMLButtonElement> | GestureResponderEvent) => void;
  isLastItem: boolean;
  isFirstItem: boolean;
}

const DragDropItem: React.FC<Props> = ({
  sectionTitle,
  handleDrag,
  draggable,
  onDragStart,
  onDragEnter,
  onDragOver,
  onMouseDown,
  containerStyle,
  handleDownPress,
  handleUpPress,
  isLastItem,
  isFirstItem,
}) => {
  const { colorScheme } = useColorScheme();

  return isNative ? (
    <Pressable
      onPressIn={handleDrag}
      className="h-[65px] flex-row pl-1 pr-2 justify-between items-center bg-neutral-50 dark:bg-neutral-950 mb-4"
      style={{
        shadowOpacity: colorScheme === 'dark' ? 0.9 : 0.05,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 4,
        width: '98%',
        alignSelf: 'center',
        elevation: 10,
      }}
    >
      <View className="flex-row items-center">
        <View className="mr-1">
          <Icon
            icon={svgIcons.dragIndicator}
            mobileSize={{ width: 32, height: 32 }}
            webSize={{ width: 32, height: 32 }}
            classNames={'py-1 px-2'}
          />
        </View>
        <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-body sm:text-cardTitleMobile">
          {sectionTitle}
        </Text>
      </View>
      <View className="flex-row">
        {!isLastItem ? (
          <Pressable className="mr-2" onPress={handleDownPress}>
            <Icon
              icon={svgIcons.chevronDownIcon}
              mobileSize={{ width: 32, height: 32 }}
              webSize={{ width: 32, height: 32 }}
              classNames={'py-1 px-1'}
            />
          </Pressable>
        ) : (
          <View
            style={{
              width: 32,
              height: 32,
              paddingHorizontal: 4,
              paddingVertical: 4,
            }}
          />
        )}
        {!isFirstItem ? (
          <Pressable onPress={handleUpPress}>
            <Icon
              icon={svgIcons.chevronUpIcon}
              mobileSize={{ width: 32, height: 32 }}
              webSize={{ width: 32, height: 32 }}
              classNames={'py-1 px-1'}
            />
          </Pressable>
        ) : (
          <View
            style={{
              width: 32,
              height: 32,
              paddingHorizontal: 4,
              paddingVertical: 4,
              marginRight: 8,
            }}
          />
        )}
      </View>
    </Pressable>
  ) : (
    <div
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      style={{
        ...containerStyle,
        height: 65,
        display: 'flex',
        padding: '0px 12px',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        backgroundColor: colorScheme === 'dark' ? '#101010' : '#f6f6f6',
        boxShadow:
          colorScheme === 'dark'
            ? '0px 10px 4px 0px rgba(0, 0, 0, 0.5)'
            : '0px 10px 4px 0px rgba(0, 0, 0, 0.035)',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div onMouseDown={onMouseDown} style={{ cursor: 'grab' }}>
          <Icon
            icon={svgIcons.dragIndicator}
            mobileSize={{ width: 32, height: 32 }}
            webSize={{ width: 32, height: 32 }}
            classNames={'mr-3'}
          />
        </div>
        <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-body sm:text-cardTitleMobile">
          {sectionTitle}
        </Text>
      </div>
      <div className="flex-row" style={{ display: 'flex' }}>
        {!isLastItem ? (
          <Pressable className="mr-3" onPress={handleDownPress}>
            <Icon
              icon={svgIcons.chevronDownIcon}
              mobileSize={{ width: 32, height: 32 }}
              webSize={{ width: 32, height: 32 }}
            />
          </Pressable>
        ) : (
          <View style={{ width: 32, height: 32 }} />
        )}
        {!isFirstItem ? (
          <Pressable onPress={handleUpPress}>
            <Icon
              icon={svgIcons.chevronUpIcon}
              mobileSize={{ width: 32, height: 32 }}
              webSize={{ width: 32, height: 32 }}
            />
          </Pressable>
        ) : (
          <View style={{ width: 32, height: 32 }} />
        )}
      </div>
    </div>
  );
};

export default DragDropItem;
