import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../../components/Layout';
import {
  NavigationParamList,
  ScreenName,
  StackNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import {
  AdminExtremeEventRead,
  AdminPostOrderBy,
  GetAdminExtremeEventsParams,
  Order,
  Pagination as IPagination,
  Role,
} from '../../../client/interfaces';
import { AdminPostFilter, Button, ExtremeEventTable, Pagination } from '../../../components';
import client from '../../../client/client';
import { useMessage } from '../../../contexts/Messages/MessageContext';
import { NavigationContextStack } from '../../../contexts/NavigationContext/NavigationContextStack';

export const emptyGetAdminExtremeEventsParams: GetAdminExtremeEventsParams = {
  orderBy: AdminPostOrderBy.createdAtUtc,
  order: Order.DESC,
  search: '',
  page: 1,
  status: undefined,
  tags: [],
};

const AdminExtremeEventListScreen: React.FC<
  StackNavigationScreenProps<NavigationParamList, ScreenName.AdminExtremeEventListScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { me, hasRole } = useAuth();
  const { setMessage } = useMessage();

  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getAdminExtremeEventsParams, setGetAdminExtremeEventsParams] =
    useState<GetAdminExtremeEventsParams>({
      ...emptyGetAdminExtremeEventsParams,
      ...route.params,
    });
  const [adminExtremeEvents, setAdminExtremeEvents] = useState<AdminExtremeEventRead[]>([]);
  const [pagination, setPagination] = useState<IPagination>();

  const getAdminArticlesParamsJSON = JSON.stringify(getAdminExtremeEventsParams);

  // Role guard
  useFocusEffect(
    useCallback(() => {
      if (me && !hasRole(Role.extremeEventAdmin)) {
        navigation.navigate(ScreenName.HomeScreen);
      } else {
        setGetAdminExtremeEventsParams(emptyGetAdminExtremeEventsParams);
      }
    }, [me?.roleList]),
  );

  const fetchAdminExtremeEvents = async () => {
    setIsLoading(true);

    try {
      const response = await client.getAdminExtremeEvents(getAdminExtremeEventsParams);
      setAdminExtremeEvents(response.items);
      setPagination(response.pagination);
    } catch (e) {
      console.error(e);
      setMessage({ message: e.message, type: 'error' });
    }

    setIsLoading(false);
  };

  useFocusEffect(
    useCallback(() => {
      fetchAdminExtremeEvents();
    }, [getAdminArticlesParamsJSON]),
  );

  useEffect(() => {
    setGetAdminExtremeEventsParams((prev) => ({ ...prev, ...route.params }));
  }, [route.params]);

  return (
    <Layout title={t('admin:extreme_event_list:title')} titleMarginBottom={false}>
      <Button
        text={t('admin:extreme_event_list:add_new')}
        variant={'fill'}
        onPress={() => navigation.navigate(ScreenName.AdminExtremeEventEditScreen)}
        size={'medium'}
        style={{ alignItems: 'flex-end', marginBottom: 40 }}
      />
      <AdminPostFilter
        params={getAdminExtremeEventsParams}
        paramSetter={setGetAdminExtremeEventsParams}
        pagination={pagination}
        type={ScreenName.AdminExtremeEventListScreen}
      />
      <ExtremeEventTable
        rows={adminExtremeEvents}
        paramSetter={setGetAdminExtremeEventsParams}
        params={getAdminExtremeEventsParams}
        refresh={fetchAdminExtremeEvents}
        isLoading={isLoading}
      />
      {pagination && (
        <Pagination
          data={pagination}
          paramSetter={setGetAdminExtremeEventsParams}
          params={getAdminExtremeEventsParams}
        />
      )}
    </Layout>
  );
};

export default AdminExtremeEventListScreen;
