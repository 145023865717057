import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { Layout, Loader, Favorites } from '../../components';
import { useTags } from '../../contexts/Tag/TagContext';
import { isMobile } from '../../utils/responsive';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const FavoritesScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.FavoritesScreen>
> = ({ navigation, route }) => {
  const { tags } = useTags();
  const { t } = useTranslation();

  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  return tags ? (
    <Layout title={!isMobile && t('navigation:favorites')}>
      <Favorites />
    </Layout>
  ) : (
    <Loader />
  );
};

export default FavoritesScreen;
