import React, { ReactNode } from 'react';
import { View, Text, GestureResponderEvent } from 'react-native';
import { isMobile } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { AdaptiveContainer } from '../index';
import { useResize } from '../../utils/resize';

interface Props {
  children: ReactNode;
  title?: string;
  isScrollView?: boolean;
  isArticleStyle?: boolean;
  layoutWithoutMenu?: boolean;
  onPressBackButton?: (event: GestureResponderEvent) => void;
  onPressSearch?: (event: GestureResponderEvent) => void;
  isHomeScreen?: boolean;
  titleMarginBottom?: boolean;
  refProp?: React.Ref<View>;
  isListScreen?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  title,
  isScrollView = true,
  isArticleStyle,
  layoutWithoutMenu,
  onPressBackButton,
  onPressSearch,
  isHomeScreen,
  titleMarginBottom = true,
  refProp,
  isListScreen,
}) => {
  const { isWidthBelowMedium } = useResize();
  const layoutStyle = {
    paddingWeb: isWidthBelowMedium ? 40 : 80,
    titleWidth: isArticleStyle && 'max-w-[640px] self-center',
  };

  // LAYOUT CONTENT PADDING
  const verticalPadding = 40;

  return isMobile ? (
    <View
      className="w-full bg-neutral-50 dark:bg-neutral-950"
      style={{
        flex: layoutWithoutMenu ? null : 1,
        minHeight: layoutWithoutMenu ? '100%' : null,
      }}
    >
      <AdaptiveContainer
        isScrollView={isScrollView}
        layoutWithoutMenu={layoutWithoutMenu}
        isArticleStyle={isArticleStyle}
        verticalPadding={verticalPadding}
        isHomeScreen={isHomeScreen}
        refProp={refProp}
        isListScreen={isListScreen}
      >
        {title ? (
          <View
            className={cln('flex-row self-center w-full', isArticleStyle && 'max-w-[640px]')}
            style={{ overflow: 'hidden' }}
          >
            <View className="flex-row w-full justify-between">
              <Text
                className={cln(
                  isArticleStyle
                    ? 'text-articleTitleMobile md:text-articleTitle'
                    : 'text-h2Mobile md:text-h2',
                  'text-neutral-950 dark:text-neutral-50 font-[sans-600]',
                )}
                style={{
                  marginBottom:
                    isArticleStyle || !titleMarginBottom
                      ? 0
                      : onPressSearch
                        ? 0
                        : onPressBackButton
                          ? 24
                          : 40,
                }}
              >
                {title}
              </Text>
            </View>
          </View>
        ) : (
          <View className="h-0" />
        )}
        {children}
      </AdaptiveContainer>
    </View>
  ) : (
    <View
      className={cln(
        'min-h-full items-center bg-neutral-50 dark:bg-neutral-950 pt-[56px] pb-20',
        layoutWithoutMenu ? 'ml-0' : 'ml-[0px]',
      )}
      style={{ paddingHorizontal: layoutStyle.paddingWeb }}
      ref={refProp}
    >
      <View
        className={cln(
          'pt-10 max-w-[1176px] w-full',
          layoutWithoutMenu ? 'items-center pl-0' : 'pl-[100px]',
        )}
      >
        {title && (
          <Text
            className={cln(
              'text-sectionTitle md:text-h2 text-neutral-950 dark:text-neutral-50 font-[sans-600]',
              layoutStyle.titleWidth,
              layoutWithoutMenu ? 'md:mb-2 max-w-[800px]' : 'md:mb-20',
              isArticleStyle ? 'mb-2 max-w-[640px] w-full self-center text-left' : 'md:mb-10',
              titleMarginBottom ? 'mb-10' : 'mb-0',
            )}
          >
            {title}
          </Text>
        )}
        {children}
      </View>
    </View>
  );
};

export default Layout;
