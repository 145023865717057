import { Dimensions, Platform, Pressable, View } from 'react-native';
import React from 'react';
import { useColorScheme } from 'nativewind';
import { BlurView } from 'expo-blur';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isMobile, isMobileWeb, isNative, isTablet, isWeb, isIos, navHeight } from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons from '../../assets';

const ProfileMenuHeader = ({ children, onPressLogo }) => {
  const { colorScheme } = useColorScheme();

  //To get the height of the statusbar = insets.top
  const insets = useSafeAreaInsets();

  // Content of screens, Layout component, gets a smaller padding below 768px so I added it to profile bar as well
  const isWidthBelowMedium = Dimensions.get('window').width < 768;
  const navOpacity = Platform.OS === 'android' ? 0.9 : 0.75;

  const backgroundOpacity = isIos ? 0.75 : 0.9;

  return (
    <BlurView
      style={[
        {
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: isMobileWeb ? 'center' : 'flex-end',
          justifyContent: isMobile ? 'space-between' : 'center',
          position: isNative ? 'absolute' : ('fixed' as 'absolute'),
          flex: 1,
          zIndex: 10,
          width: '100%',
          //On mobile we add the insets.top so the header is fully visible, this is instead of safeAreaView
          paddingTop: insets.top,
          paddingHorizontal: isWidthBelowMedium ? 12 : 32,
          backgroundColor:
            colorScheme === 'dark'
              ? `rgba(16,16,16,${backgroundOpacity})`
              : `rgba(246,246,246,${backgroundOpacity})`,
          borderBottomColor: colorScheme === 'dark' ? '#212121' : '#EEEEEE',
          borderBottomWidth: 1,
        }, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isWeb ? { transition: 'background-color 0.3s ease-in-out' } : null,
      ]}
      intensity={30}
      tint={colorScheme}
    >
      {/*Container for logo, notification button and profileImage button*/}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: navHeight,
        }}
      >
        {/* LOGO */}
        {isMobile && (
          <Pressable
            onPress={onPressLogo}
            style={{
              paddingTop: 12,
              paddingRight: 8,
              paddingBottom: 12,
            }}
          >
            <Icon
              icon={svgIcons.logo}
              mobileSize={{ width: 80, height: 18.5 }}
              webSize={{ width: 80, height: 18.5 }}
            />
          </Pressable>
        )}
        {/*Container for notification button and profileImage button*/}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {children}
        </View>
      </View>
    </BlurView>
  );
};

export default ProfileMenuHeader;
