import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName, StackName } from '../types';
import {
  AdminArticleListScreen,
  AdminEventListScreen,
  AdminExtremeEventListScreen,
  AdminIndexScreen,
} from '../../screens';

const AdminStack = createStackNavigator();

const AdminStackNavigator = () => {
  const { t } = useTranslation();
  return (
    <AdminStack.Navigator
      screenOptions={{
        gestureEnabled: true, // Ensures the swipe gesture is enabled
        headerShown: false,
      }}
    >
      <AdminStack.Screen
        name={ScreenName.ListScreen}
        component={AdminIndexScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: false,
            title: t('navigation:admin_index'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminArticleListScreen}
        component={AdminArticleListScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: false,
            title: t('navigation:admin_index'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminEventListScreen}
        component={AdminEventListScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: false,
            title: t('navigation:admin_index'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminExtremeEventListScreen}
        component={AdminExtremeEventListScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: false,
            title: t('navigation:admin_index'),
          };
        }}
      />
    </AdminStack.Navigator>
  );
};

export default AdminStackNavigator;
