import { ColorSchemeName, Linking, Alert } from 'react-native';
import { t } from 'i18next';
import { ThemeOption } from '../resources/interfaces';
import { isIos, isNative, isWeb } from './responsive';

export const getOppositeColorScheme = (colorScheme: ColorSchemeName | ThemeOption) => {
  return Object.values(ThemeOption).find((c) => c !== colorScheme);
};

export const distributeElements = <T>(inputArray: T[], columns): T[][] => {
  const output = Array.from({ length: columns }, () => []);

  for (let i = 0; i < inputArray.length; i++) {
    const targetIndex = i % columns;
    output[targetIndex].push(inputArray[i]);
  }

  return output;
};

export const handleLinkPress = (url: string) => {
  if (isNative) {
    Linking.openURL(url);
  } else {
    if (typeof window !== 'undefined') {
      // We are running in a web environment
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    } else {
      // We are running in a React Native environment
      Linking.openURL(url);
    }
  }
};

export const addressPattern =
  /\b(street|utca|u\.|road|út|ave(nue)?|sugárút|square|tér|blvd|boulevard|krt\.|körút|alley|köz|quay|rakpart|promenade|sétány|park)\b\s?\d*|u\.\s\d+/i;

export const openInMaps = async (location, showActionSheetWithOptions, setMessage) => {
  const options = [
    {
      translation: t('events:open_in_google_maps'),
      url: `comgooglemaps://?q=${encodeURIComponent(location)}`,
    },
    isIos && {
      translation: t('events:open_in_maps'),
      url: `http://maps.apple.com/?q=${encodeURIComponent(location)}`,
    },
    {
      translation: t('extreme_events:cancel'),
    },
  ].filter(Boolean);

  const cancelButtonIndex = options.length - 1;

  if (isNative) {
    showActionSheetWithOptions(
      {
        options: options.map((option) => option.translation),
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        if (buttonIndex === cancelButtonIndex) {
          return;
        }

        try {
          await Linking.openURL(options[buttonIndex].url);
        } catch (error) {
          try {
            await Linking.openURL(
              `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`,
            );
          } catch (googleMapsWebError) {
            setMessage({ message: t('error_messages:default'), type: 'error' });
          }
        }
      },
    );
  } else {
    handleLinkPress(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`,
    );
  }
};
