import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import {
  GetAdminArticlesParams,
  GetAdminEventsParams,
  GetAdminExtremeEventsParams,
  ModuleKey,
} from '../client/interfaces';
import { ErrorType } from '../resources/interfaces';

export type NavigationParamList = {
  [ScreenName.HomeScreen]: { screen: ScreenName.HomeStackScreen };
  [ScreenName.FavoritesScreen]: undefined;
  [ScreenName.PlatformsScreen]: undefined;
  [ScreenName.SettingsScreen]: undefined;
  [ScreenName.ArticlesScreen]: { screen: ScreenName.ListScreen };
  [ScreenName.EventsScreen]: { screen: ScreenName.ListScreen };
  [ScreenName.ProfileScreen]: undefined;
  [ScreenName.ArticleScreen]: { postId: number };
  [ScreenName.EventScreen]: { postId: number };
  [ScreenName.PostFilterScreen]: { fromScreen: ScreenName };
  [ScreenName.OnboardingScreen]: undefined;
  [ScreenName.AdminIndexScreen]: undefined;
  [ScreenName.AdminArticleListScreen]: GetAdminArticlesParams;
  [ScreenName.AdminArticleEditScreen]: { postId?: number };
  [ScreenName.AdminExtremeEventListScreen]: GetAdminExtremeEventsParams;
  [ScreenName.AdminEventListScreen]: GetAdminEventsParams;
  [ScreenName.AdminExtremeEventEditScreen]: { postId?: number };
  [ScreenName.AdminEventEditScreen]: { postId?: number };
  [ScreenName.ErrorScreen]: { type?: ErrorType };
  [ScreenName.PersonalizationScreen]: undefined;
  [ScreenName.NotificationsScreen]: { fromScreen: ScreenName };
  [ScreenName.SplashScreen]: undefined;
  '*': { type?: ErrorType };
};

export enum Navigator {
  main = 'main',
  profile = 'profile',
}

export interface Screen {
  name: ScreenName;
  route: string;
  translation: string;
  navigator?: Navigator;
  icon?: any;
  moduleKey?: ModuleKey; // Ki-be kapcsolható backenden
  isTab?: boolean;
}

export enum ScreenName {
  HomeScreen = `${Navigator.main}/HomeScreen`,
  EventsScreen = `${Navigator.main}/EventsScreen`,
  FavoritesScreen = `${Navigator.profile}/FavoritesScreen`,
  PlatformsScreen = `${Navigator.main}/PlatformsScreen`,
  ArticlesScreen = `${Navigator.main}/ArticlesScreen`,
  AdminIndexScreen = `${Navigator.profile}/AdminIndexScreen`,
  AdminArticleListScreen = `AdminArticleListScreen`,
  AdminArticleEditScreen = `AdminArticleEditScreen`,
  AdminExtremeEventListScreen = `AdminExtremeEventListScreen`,
  AdminExtremeEventEditScreen = `AdminExtremeEventEditScreen`,
  AdminEventListScreen = `AdminEventListScreen`,
  AdminEventEditScreen = `AdminEventEditScreen`,
  SettingsScreen = `${Navigator.profile}/SettingsScreen`,
  ProfileScreen = `ProfileScreen`,
  OnboardingScreen = `OnboardingScreen`,
  ArticleScreen = `ArticleScreen`,
  EventScreen = `EventScreen`,
  ErrorScreen = `ErrorScreen`,
  PostFilterScreen = `PostFilterScreen`,
  NotFound = `*`,
  PersonalizationScreen = 'PersonalizationScreen',
  NotificationsScreen = `NotificationsScreen`,
  SplashScreen = 'SplashScreen',
  ListScreen = 'list',
  HomeStackScreen = 'home',
}

export enum StackName {
  HomeStack = 'HomeStack',
  EventsStack = 'EventsStack',
  ArticlesStack = 'ArticlesStack',
  HeaderStack = 'HeaderStack',
}

export type NotificationRedirectScreenName = Extract<
  ScreenName,
  ScreenName.ArticleScreen | ScreenName.EventScreen | ScreenName.HomeScreen
>;

export type TabNavigationScreenProps<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = keyof ParamList,
  NavigatorID extends string | undefined = undefined,
> = {
  navigation: NavigationProp<ParamList, RouteName, NavigatorID>;
  route: RouteProp<ParamList, RouteName>;
};

export type StackNavigationScreenProps<
  ParamList extends Record<string, object | undefined>,
  RouteName extends keyof ParamList = keyof ParamList,
> = {
  navigation: NativeStackNavigationProp<ParamList, RouteName>;
  route: RouteProp<ParamList, RouteName>;
};

export type TabNavigationOptions = {
  title?: string;
};

export type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};
