import { Text } from 'react-native';
import React from 'react';
import { handleLinkPress } from '../../utils';

interface Props {
  text: string;
}

const HyperlinkedText: React.FC<Props> = ({ text }) => {
  const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/g;

  const parts = text.split(urlRegex);

  return (
    <Text
      selectable={true}
      className={'font-[sans-400] text-neutral-950 dark:text-neutral-50 text-body'}
    >
      {parts.map((part, index) => {
        if (urlRegex.test(part)) {
          // If the part matches the URL pattern, make it clickable
          const visibleText = part.replace(/https?:\/\//, '');
          return (
            <Text
              key={index}
              className={
                'font-[sans-400] text-primary-600 dark:text-primary-500 mt-6 text-body underline'
              }
              onPress={() => handleLinkPress(part)}
            >
              {visibleText}
            </Text>
          );
        }
        return part;
      })}
    </Text>
  );
};

export default HyperlinkedText;
