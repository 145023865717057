import React, { useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { cln } from '../../utils/classnames';
import { isMobile, isTablet } from '../../utils/responsive';
import { Notification as NotificationInterface, PostType } from '../../client/interfaces';
import { Icon } from '../index';
import svgIcons from '../../assets';
import { usePushNotifications } from '../../contexts/PushNotification/PushNotificationContext';

interface Props {
  notificationData: NotificationInterface;
}
const Notification: React.FC<Props> = ({ notificationData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardType: PostType = notificationData.type;
  const { onNotificationPress } = usePushNotifications();

  const notificationBackground = isMobile ? 'bg-none' : 'bg-white dark:bg-black';

  return (
    notificationData && (
      <View className="w-full">
        <Pressable
          className={cln(
            'flex-row items-center px-4',
            isMobile && !isTablet ? 'py-[10px] h-[72px]' : 'py-[18px] h-[88px]',
            isHovered ? 'bg-neutral-200 dark:bg-neutral-910' : notificationBackground,
          )}
          onPress={async () => {
            await onNotificationPress(notificationData.type, notificationData.postId);
          }}
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
        >
          {cardType === PostType.extremeEvent ? (
            <>
              <View
                className={cln(
                  'w-3 h-3 rounded-lg mr-2',
                  notificationData?.seenAtUtc ? 'bg-none' : 'bg-primary-600 dark:bg-primary-500',
                )}
              />
              <Icon
                icon={notificationData?.seenAtUtc ? svgIcons.alertReadIcon : svgIcons.alertIcon}
                mobileSize={{ width: 48, height: 48 }}
                webSize={{ width: 48, height: 48 }}
                classNames={'left-[4px]'}
              />
            </>
          ) : (
            <>
              <View
                className={cln(
                  'w-3 h-3 rounded-lg mr-4',
                  notificationData?.seenAtUtc ? 'bg-none' : 'bg-primary-600 dark:bg-primary-500',
                )}
              />

              <Image
                source={{ uri: notificationData?.imageUrl }}
                resizeMode={'cover'}
                width={40}
                height={40}
                style={{ borderRadius: 20, height: 40, width: 40 }}
              />
            </>
          )}

          {/*TEXT CONTAINER*/}
          <View style={{ flex: 1 }}>
            <Text
              className={cln(
                'text-notificationMessage font-[sans-700] ml-4 py-[2px] max-h-12 overflow-hidden',
                !notificationData?.seenAtUtc && cardType === PostType.extremeEvent
                  ? 'text-danger-500'
                  : !notificationData?.seenAtUtc && cardType !== PostType.extremeEvent
                    ? 'text-neutral-950 dark:text-neutral-50'
                    : 'text-neutral-600 dark:text-neutral-400',
              )}
            >
              {notificationData?.titleTranslation}
            </Text>
            <Text
              className={cln(
                'text-notificationSubtext font-[sans-400] ml-4 py-[2px]',
                notificationData?.seenAtUtc
                  ? 'text-neutral-600 dark:text-neutral-400'
                  : 'text-neutral-900 dark:text-neutral-300',
              )}
            >
              {notificationData?.timeAgo}
            </Text>
          </View>
        </Pressable>
      </View>
    )
  );
};
export default Notification;
