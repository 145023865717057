import React, { createContext, useState } from 'react';

import { Article, GetArticlesParams, Order, PostOrderBy } from '../../client/interfaces';

interface ArticlesParamsContextType {
  getArticlesParams: GetArticlesParams;
  setGetArticlesParams: React.Dispatch<React.SetStateAction<GetArticlesParams>>;
  nextPageIdentifier: number;
  setNextPageIdentifier: React.Dispatch<React.SetStateAction<number>>;
  allArticles: Article[];
  setAllArticles: React.Dispatch<React.SetStateAction<Article[]>>;
  resetInfiniteScroll: () => void;
  emptyGetArticlesParams: GetArticlesParams;
  emptyArticlesFilter: () => void;
}

const emptyGetArticlesParams: GetArticlesParams = {
  orderBy: PostOrderBy.publishedAtUtc,
  order: Order.DESC,
  page: 1,
  perPage: 9,
  search: '',
  tags: [],
  isBookmarked: false,
};

const ArticlesParamsContext = createContext<ArticlesParamsContextType>({
  getArticlesParams: emptyGetArticlesParams,
  setGetArticlesParams: () => {},
  nextPageIdentifier: 0,
  setNextPageIdentifier: () => {},
  allArticles: [],
  setAllArticles: () => {},
  resetInfiniteScroll: () => {},
  emptyGetArticlesParams,
  emptyArticlesFilter: () => {},
});

export const ArticlesProvider = ({ children }) => {
  const [getArticlesParams, setGetArticlesParams] = useState({ ...emptyGetArticlesParams });
  const [nextPageIdentifier, setNextPageIdentifier] = useState<number>(0);
  const [allArticles, setAllArticles] = useState<Article[]>([]);
  const resetInfiniteScroll = () => {
    setNextPageIdentifier(0);
  };

  function emptyArticlesFilter() {
    setGetArticlesParams((prevState) => ({
      ...prevState,
      tags: [],
      isBookmarked: false,
      search: '',
    }));
  }

  return (
    <ArticlesParamsContext.Provider
      value={{
        emptyGetArticlesParams,
        getArticlesParams,
        setGetArticlesParams,
        nextPageIdentifier,
        setNextPageIdentifier,
        allArticles,
        setAllArticles,
        resetInfiniteScroll,
        emptyArticlesFilter,
      }}
    >
      {children}
    </ArticlesParamsContext.Provider>
  );
};

export { ArticlesParamsContext };
