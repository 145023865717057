import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from 'react-native';
import * as Notifications from 'expo-notifications';
import client from '../../client/client';
import { Notification } from '../../client/interfaces';
import { useMessage } from '../Messages/MessageContext';

interface NotificationContext {
  notifications: Notification[];
  fetchNotifications: () => Promise<void>;
  timeAgoCalc: () => void;
  setNotificationsSeen: () => Promise<void>;
}

const Context = createContext<NotificationContext>({
  notifications: [],
  fetchNotifications: async () => {
    return;
  },
  timeAgoCalc: () => {
    return;
  },
  setNotificationsSeen: async () => {
    return;
  },
});

export const useNotifications = () => useContext(Context);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setMessage } = useMessage();
  const { t } = useTranslation();
  const appState = useRef(AppState.currentState);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const fetchNotifications = async () => {
    try {
      const notificationsRes = await client.getNotifications();
      setNotifications(notificationsRes);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  const setNotificationsSeen = async () => {
    try {
      const seenIds = notifications?.flatMap((notification) => {
        return !notification.seenAtUtc ? [notification.id] : [];
      });
      await client.setNotificationsSeen({
        ids: seenIds,
      });
      await Notifications.setBadgeCountAsync(0);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  // RUNNING FETCH WHEN APP IS ACTIVE EVERY 5 MIN
  useEffect(() => {
    let intervalId;
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        intervalId = setInterval(() => {
          fetchNotifications();
        }, 300000); //5minutes
      } else {
        clearInterval(intervalId);
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const timeAgoCalc = () => {
    setNotifications((prevNotifications) => {
      return prevNotifications.map((notification) => {
        const timeAgoMinutes =
          (new Date().getTime() - new Date(notification.createdAtUtc).getTime()) / 60000;
        const timeAgoHours = timeAgoMinutes / 60;

        let correctTime;

        if (timeAgoMinutes > 60 && timeAgoMinutes < 1440) {
          correctTime = `${Math.floor(timeAgoHours)} ${
            timeAgoMinutes / 60 < 2 ? t('notifications:hour_ago') : t('notifications:hours_ago')
          }`;
        } else if (timeAgoMinutes < 60) {
          correctTime = `${Math.floor(timeAgoMinutes)} ${
            timeAgoMinutes < 2 ? t('notifications:minute_ago') : t('notifications:minutes_ago')
          }`;
        } else if (timeAgoMinutes > 1440) {
          const date = new Date(notification.createdAtUtc);
          correctTime =
            date.toLocaleDateString() +
            ' - ' +
            date.getHours().toString().padStart(2, '0') +
            ':' +
            date.getMinutes().toString().padStart(2, '0');
        }
        return { ...notification, timeAgo: correctTime };
      });
    });
  };

  const value: NotificationContext = useMemo(
    () => ({
      notifications: notifications || [],
      fetchNotifications,
      timeAgoCalc,
      setNotificationsSeen,
    }),
    [notifications],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
