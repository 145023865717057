import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, Modal } from 'react-native';
import { BlurView } from 'expo-blur';
import { useColorScheme } from 'nativewind';
import Button from '../Button';
import { PropsWithClassName } from '../../resources/interfaces';
import { cln } from '../../utils/classnames';
import { isMobile, isNative } from '../../utils/responsive';

interface ModalProps extends PropsWithChildren, PropsWithClassName {
  title?: string;
  showModal: boolean;
  closeModal: () => void;
  callback: () => void;
  description?: string;
  cancelText?: string;
  yesText?: string;
  isValid?: boolean;
  scrollable?: boolean;
  instantCallback?: boolean;
}

const CustomModal: React.FC<ModalProps> = (
  {
    title,
    showModal,
    closeModal,
    description,
    callback,
    cancelText,
    yesText,
    children,
    isValid = true,
    classNames,
    scrollable = false,
    instantCallback = false,
  },
  ref,
) => {
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();

  useEffect(() => {
    if (showModal && instantCallback) {
      callback();
    }
  }, [showModal, instantCallback]);

  const blurBackgroundOpacity = isNative ? 0.75 : 0.8;

  return (
    <Modal
      animationType="fade"
      presentationStyle={'overFullScreen'}
      visible={showModal && !instantCallback}
      transparent={true}
    >
      <BlurView
        style={{
          height: '100%',
          width: '100%',
          backgroundColor:
            colorScheme === 'dark'
              ? `rgba(16,16,16,${blurBackgroundOpacity})`
              : `rgba(246,246,246,${blurBackgroundOpacity})`,
        }}
        intensity={isNative ? 30 : 40}
        tint={colorScheme}
        experimentalBlurMethod={'dimezisBlurView'}
      >
        <View
          className={cln(
            'bg-neutral-50/90 dark:bg-neutral-950/90 items-center justify-center relative z-50 border border-neutral-800 dark:border-neutral-200 mx-5 py-9 max-w-[584px]',
            isMobile ? 'px-6' : 'px-9',
            classNames,
          )}
          style={{
            alignSelf: 'center',
            marginVertical: 'auto',
          }}
        >
          <View
            className={cln(
              'mb-9',
              scrollable && 'max-h-[500px] min-h-[400px] overflow-y-auto !px-10 !py-1 mt-10',
            )}
          >
            <Text className="text-modalTitleMobile md:text-modalTitle font-[sans-600] text-neutral-950 dark:text-neutral-50">
              {title}
            </Text>
            {!!description && (
              <Text className={cln('text-subHeader text-neutral-950 dark:text-neutral-50 mt-2')}>
                {description}
              </Text>
            )}
            {!!children && <View className={cln(!scrollable && 'mb-12')}>{children}</View>}
          </View>
          <View
            className={cln(scrollable && 'mt-10', isMobile ? 'flex-column min-w-full' : 'flex-row')}
          >
            <Button
              size="medium"
              variant="fill"
              onPress={closeModal}
              text={cancelText || t('common.cancel')}
              style={{ marginBottom: isMobile ? 12 : 0 }}
            />
            <Button
              size="medium"
              variant="outline"
              onPress={callback}
              text={yesText || t('common.yes')}
              disabled={!isValid}
            />
          </View>
        </View>
      </BlurView>
    </Modal>
  );
};

export default CustomModal;
