import React, { useState, ReactNode } from 'react';
import { Text, Pressable, View, StyleProp, ViewStyle } from 'react-native';
import { t } from 'i18next';
import { Icon, ScaledImage } from '../index';
import svgIcons from '../../assets';
import { cln } from '../../utils/classnames';
import { isMobile, isNative } from '../../utils/responsive';
import { useResize } from '../../utils/resize';

interface DateObject {
  dateFirstLine: string;
  dateSecondLine: string;
  endDate: string;
  endTime: string;
  fullEndDate: string;
}

interface Props {
  title: string;
  date: DateObject;
  imageUrl?: string;
  children: ReactNode;
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
}

const ExtremeEvent: React.FC<Props> = ({
  title,
  date,
  imageUrl,
  children,
  onPress,
  containerStyle,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const { isWidthBelowLarge, isWidthBelowMedium } = useResize();

  function toggleEvent() {
    setIsOpened((prevValue) => !prevValue);
  }

  return (
    <View className={cln('w-full')} style={containerStyle}>
      <Pressable
        onPress={onPress}
        className={cln(
          'flex-row items-center self-end py-[3px] pl-[6px] mb-2',
          !isNative && 'flex-1',
        )}
      >
        <Icon
          icon={svgIcons.closeIcon}
          mobileSize={{ width: 18, height: 18 }}
          webSize={{ width: 18, height: 18 }}
          classNames={'mr-3'}
        />
        <Text className="text-xs font-[sans-400] text-neutral-930 dark:text-neutral-50">
          {t('navigation:close')}
        </Text>
      </Pressable>

      <Pressable onPress={toggleEvent}>
        <View className="w-full flex-row bg-neutral-100 dark:bg-neutral-930 relative">
          <View className="w-[3px] h-full bg-danger-500" />
          <View className="w-full px-6 py-4">
            <View className={cln('justify-between flex-row w-full', isOpened && 'mb-4')}>
              <View
                className={cln(
                  isMobile || isWidthBelowLarge
                    ? 'flex-column items-start'
                    : 'flex-row items-center',
                )}
                style={{ width: '85%' }}
              >
                <Text
                  className={cln(
                    'mr-3 font-[sans-700] text-lead text-neutral-950 dark:text-neutral-50',
                    (isMobile || isWidthBelowLarge) && 'mb-[10px]',
                  )}
                >
                  {title}
                </Text>
                {isWidthBelowMedium ? (
                  <View>
                    <Text className="font-[sans-600] text-label text-neutral-950 dark:text-neutral-50 top-[1px]">
                      {date.dateFirstLine} {date.dateSecondLine ? '-' : ''}
                    </Text>
                    <Text className="font-[sans-600] text-label text-neutral-950 dark:text-neutral-50 top-[1px]">
                      {date.dateSecondLine}
                    </Text>
                  </View>
                ) : (
                  <Text className="font-[sans-600] text-label text-neutral-950 dark:text-neutral-50 top-[1px]">
                    {`${date.dateFirstLine}${date.dateSecondLine ? ' - ' + date.dateSecondLine : ''}`}
                  </Text>
                )}
              </View>
              <View>
                <Icon
                  icon={isOpened ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                  mobileSize={{ width: 32, height: 32 }}
                  webSize={{ width: 32, height: 32 }}
                />
              </View>
            </View>
            <View className={cln('justify-start', !isOpened && 'hidden')}>
              <Text className="text-body text-neutral-950 dark:text-neutral-50">{children}</Text>
              {!!imageUrl && (
                <ScaledImage
                  resizeMode="contain"
                  style={{ marginTop: isMobile ? 6 : 8, alignSelf: 'flex-start' }}
                  width={isMobile ? '100%' : undefined}
                  height={isMobile ? undefined : 200}
                  uri={imageUrl}
                />
              )}
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );
};

export default ExtremeEvent;
