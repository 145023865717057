import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import dayjs from 'dayjs';
import { useFocusEffect } from '@react-navigation/core';
import { t } from 'i18next';
import { CalendarCardList, Loader } from '../../components';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { isNative } from '../../utils/responsive';
import client from '../../client/client';
import { cln } from '../../utils/classnames';
import { CalendarEvent } from '../../client/interfaces';

const TodaySection: React.FC = () => {
  const { setMessage } = useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [processedEvents, setProcessedEvents] = useState<CalendarEvent[]>([]);
  const [lastShowNowIndex, setLastShowNowIndex] = useState<number | null>(0);
  const [nextEventIndex, setNextEventIndex] = useState<number | null>(0);
  const [isError, setIsError] = useState(false);

  const fetchCalendarEvents = async () => {
    try {
      setIsLoading(true);
      const calendarEventsResponse = await client.getCalendarEvents();
      setCalendarEvents(calendarEventsResponse || []);
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
      setIsError(true);
    }
    setIsLoading(false);
  };

  useFocusEffect(
    useCallback(() => {
      fetchCalendarEvents();
    }, []),
  );

  const now = dayjs();

  useEffect(() => {
    const updatedEvents = calendarEvents?.map((card) => {
      const startDateTime = dayjs(`${card.startDate}T${card.startTime || '00:00:01'}`);
      const endDateTime = dayjs(`${card.endDate}T${card.endTime || '23:59:59'}`);

      const isEventNow = now.isAfter(startDateTime) && now.isBefore(endDateTime);
      const isTodayEvent = startDateTime.isSame(now, 'day') && endDateTime.isSame(now, 'day');

      const isTodayEventNow = isEventNow && isTodayEvent;
      const isOngoingEventNow = isEventNow && !isTodayEvent;

      const shouldShowNow = isTodayEventNow || isOngoingEventNow;
      const shouldShowNowLine = shouldShowNow && isEventNow;

      const next = now.isBefore(startDateTime);

      return {
        ...card,
        shouldShowNow,
        shouldShowNowLine,
        startDateTime,
        endDateTime,
        next,
      };
    });

    updatedEvents.sort((a, b) => {
      const now = new Date();

      const parseDateTime = (date, time) => new Date(`${date}T${time || '23:59:59'}`);
      const endA = parseDateTime(a.endDate, a.endTime);
      const endB = parseDateTime(b.endDate, b.endTime);

      const isPastA = endA < now;
      const isPastB = endB < now;

      if (isPastA && !isPastB) return -1;
      if (!isPastA && isPastB) return 1;

      return 0;
    });

    // Index numbers
    const lastShowNowIndexNum = updatedEvents.findLastIndex((item) => item.shouldShowNow);
    const nextIndexNum = updatedEvents.findIndex((item) => item.next);

    setLastShowNowIndex(lastShowNowIndexNum);
    setNextEventIndex(nextIndexNum);

    // FINAL EVENTS
    const finalEvents = updatedEvents.map((item, index) => {
      return {
        ...item,
        shouldShowNow: index === lastShowNowIndexNum,
        next: index === nextIndexNum,
      };
    });

    setProcessedEvents(finalEvents);
  }, [calendarEvents]);

  const noEvents = isError ? (
    <View className={cln('flex-row items-center flex-1 flex-wrap', isNative && 'px-[30px]')}>
      <Text className="font-[sans-400] text-neutral-930 dark:text-neutral-50 text-modalTitleMobile md:text-h3">
        {t('today:error')}
      </Text>
    </View>
  ) : (
    <View className={cln('flex-row items-center flex-1 flex-wrap', isNative && 'px-[30px]')}>
      <Text className="font-[sans-600] text-primary-600 dark:text-primary-500 text-modalTitleMobile md:text-sectionTitle">
        {t('today:no')}{' '}
      </Text>
      <Text className="font-[sans-600] text-neutral-930 dark:text-neutral-50 text-modalTitleMobile md:text-sectionTitle">
        {t('today:upcoming')}{' '}
      </Text>
      <Text className="font-[sans-600] text-neutral-930 dark:text-neutral-50 text-modalTitleMobile md:text-sectionTitle">
        {t('today:event')}
      </Text>
    </View>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <View style={{ flex: isNative ? 1 : null }}>
      {calendarEvents.length > 0 && !isLoading ? (
        <CalendarCardList
          items={processedEvents}
          position={lastShowNowIndex + 1 || nextEventIndex + 1 || processedEvents.length}
        />
      ) : (
        noEvents
      )}
    </View>
  );
};

export default TodaySection;
