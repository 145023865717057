import React, { useState, RefObject } from 'react';
import { Text, Pressable, FlatList } from 'react-native';
import { cln } from '../../utils/classnames';
import { Tag } from '../../client/interfaces';
import { isMobile, isNative, isSmallMobile } from '../../utils/responsive';

interface Props {
  onPress: (option: Tag) => void;
  dropdownTags: Tag[];
  dropdownRef?: RefObject<FlatList>;
}

const Dropdown: React.FC<Props> = ({ onPress, dropdownTags, dropdownRef }) => {
  const [listItemHovered, setListItemHovered] = useState(null);
  const [listItemPressed, setListItemPressed] = useState(null);

  return (
    <FlatList
      bounces={false}
      ref={dropdownRef}
      data={dropdownTags}
      renderItem={(itemData) => (
        <Pressable
          key={itemData.item.id}
          className={cln(
            'h-12 px-2 justify-center',
            listItemHovered === itemData.item.id
              ? 'bg-neutral-300 dark:bg-neutral-700'
              : listItemPressed === itemData.item.id
                ? 'bg-neutral-100 dark:bg-neutral-910'
                : 'bg-neutral-200 dark:bg-neutral-900',
          )}
          onPress={() => {
            onPress(itemData.item);
          }}
          onPressIn={() => {
            setListItemHovered(null);
            setListItemPressed(itemData.item.id);
          }}
          onPressOut={() => setListItemPressed(null)}
          onHoverIn={() => setListItemHovered(itemData.item.id)}
          onHoverOut={() => setListItemHovered(null)}
        >
          <Text className="font-[sans-400] text-input text-neutral-950 dark:text-neutral-50">
            {itemData.item.translationValue}
          </Text>
        </Pressable>
      )}
      className="w-full absolute z-100 top-12"
      style={{
        maxHeight: isSmallMobile ? 248 : isMobile ? 352 : 288,
      }}
    />
  );
};

export default Dropdown;
