import React, { ReactNode } from 'react';
import { Text, View, Pressable, GestureResponderEvent } from 'react-native';
import { useTranslation } from 'react-i18next';
import svgIcons from '../../assets';
import { Icon } from '../index';

interface Props {
  children?: ReactNode;
  title: string;
  onPressFollowAll?: (event: GestureResponderEvent) => void;
  allFollowed: boolean;
}

const FavoritesGroup: React.FC<Props> = ({ children, title, onPressFollowAll, allFollowed }) => {
  const { t } = useTranslation();
  return (
    <View className="bg-none sm:bg-neutral-100 sm:dark:bg-neutral-930 p-0 sm:p-10 mb-10 sm:mb-10">
      <View className="mb-5 sm:mb-[60px] flex-column items-start sm:items-center sm:flex-row justify-between">
        <Text className="text-sectionSubtitle text-neutral-950 dark:text-neutral-50 font-[sans-400]">
          {title}
        </Text>
        <View className="self-end">
          <Pressable
            onPress={onPressFollowAll}
            className="flex-row items-center justify-center py-2.5"
          >
            <Text className="text-primary-600 dark:text-primary-500 text-body mr-3 font-[sans-400]">
              {allFollowed ? t('common:unfollow_all') : t('common:follow_all')}
            </Text>
            <Icon
              icon={allFollowed ? svgIcons.starIconFillPrimary : svgIcons.starIconPrimary}
              mobileSize={{ width: 20, height: 20 }}
              webSize={{ width: 20, height: 20 }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style={{ bottom: 2 }}
            />
          </Pressable>
        </View>
      </View>
      <View className="flex-wrap flex-row">{children}</View>
    </View>
  );
};

export default FavoritesGroup;
