import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import client from '../../client/client';
import { LoginResponse, MeResponse, PersonaType, Role } from '../../client/interfaces';
import setupInterceptors from '../../client/setupInterceptors';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import { axiosInstance } from '../../client/axiosInstance';
import { useMessage } from '../Messages/MessageContext';
import { StorageKey } from '../../utils/storage';
import useConditionalStorage from '../../hooks/useConditionalStorage';

type authContextType = {
  auth: LoginResponse | null;
  setAuth: (auth: LoginResponse) => void;
  logout: () => Promise<void>;
  refresh: () => Promise<LoginResponse | null>;
  me: MeResponse | null;
  fetchMe: () => Promise<void>;
  isAdmin: boolean;
  hasRole: (role: Role) => boolean;
  hasPersona: (personaType: PersonaType) => boolean;
  isStudent: boolean;
  isEmployee: boolean;
};

const authContextDefaultValues: authContextType = {
  auth: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuth: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: async () => {
    return null;
  },
  me: null,
  fetchMe: async () => {
    return null;
  },
  isAdmin: false,
  hasRole: () => false,
  hasPersona: () => false,
  isStudent: false,
  isEmployee: false,
};

export const AuthContext = createContext<authContextType>(authContextDefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: Readonly<PropsWithChildren>) {
  const [auth, setAuth, removeAuth] = useConditionalStorage(StorageKey.auth);
  const [me, setMe] = useState<MeResponse>(null);
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);

  const isFirstRender = useIsFirstRender();
  const { setMessage } = useMessage();

  useEffect(() => {
    if (isFirstRender) {
      setupInterceptors(handleRefresh, handleLogout, axiosInstance);
    }
  }, [isFirstRender]);

  useEffect(() => {
    setIsStudent(hasPersona(PersonaType.student));
    setIsEmployee(hasPersona(PersonaType.employee));
  }, [me]);

  const handleRefresh = async () => {
    try {
      const response = await client.refreshToken();

      await setAuth({
        ...auth,
        user: response.user,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        tokenType: response.tokenType,
      });

      return response;
    } catch (error) {
      console.error(error);
      await handleLogout();
    }
  };

  const handleLogout = async () => {
    try {
      await client.logout();
    } catch (error) {
      // If there is any error, it's because of the access / refresh token expiry.
      // Logging out, and deleting user data regardless of successful api communication.
      console.error('logout error', error);
    }

    await removeAuth();
    window?.sessionStorage?.clear();
  };

  const fetchMe = useCallback(async () => {
    try {
      const meResponse = await client.getMe();
      setMe(meResponse);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  }, []);

  const hasRole = (role: Role) => {
    if (!me) {
      return false;
    }
    return me.roleList.includes(role) || me.roleList.includes(Role.superAdmin);
  };

  const hasPersona = (personaType: PersonaType) => {
    if (!me) {
      return false;
    }
    return me.personas.some((p) => p.personaType === personaType);
  };

  const value = useMemo(
    () => ({
      auth,
      setAuth: setAuth,
      logout: handleLogout,
      refresh: handleRefresh,
      me,
      fetchMe,
      isAdmin: (me?.roleList || []).length > 0,
      hasRole,
      hasPersona,
      isStudent,
      isEmployee,
    }),
    [auth, me, isStudent, isEmployee],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
