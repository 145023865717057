import React, { useState } from 'react';
import { GestureResponderEvent, Pressable, Text, View } from 'react-native';
import svgIcons from '../../assets';
import { cln } from '../../utils/classnames';
import { isMobile } from '../../utils/responsive';
import { Icon } from '../index';
import { TagVariant } from '../../resources/interfaces';
import { useResize } from '../../utils/resize';

//If we need this component to have more functionality or it becomes more complex,
//we should divide the followTag into a separate component

interface Props {
  children: string;
  onPress?: (event: GestureResponderEvent) => void;
  variant: TagVariant;
  isFollowed?: boolean;
  isNotified?: boolean;
  isFilled?: boolean;
  disabled?: boolean;
  iconTagDisabled?: boolean;
  onPressFollow?: () => void;
  onPressNotify?: () => void;
  onPressRemove?: () => void;
  iconName?: string;
  margin?: string;
  width?: string;
}

interface TagStyle {
  tag: {
    size: string;
    background: {
      default: string;
      hover?: string;
      press?: string;
      disabled?: string;
    };
  };
  text: string;
}

interface TagStyles {
  [key: string]: TagStyle;
}

interface FollowTagStyle {
  [key: string]: string;
}

interface FollowTagStyles {
  size: string;
  tag: FollowTagStyle;
  text: string;
  textDisabled: string;
}

const Tag: React.FC<Props> = ({
  children,
  variant,
  onPress,
  disabled,
  iconTagDisabled,
  isFollowed,
  isNotified,
  onPressFollow,
  onPressNotify,
  onPressRemove,
  iconName,
  isFilled = false,
  margin,
  width,
}) => {
  const [tagIsHovered, setTagIsHovered] = useState(false);
  const [iconIsHovered, setIconIsHovered] = useState(false);
  const [tagIsPressed, setTagIsPressed] = useState(false);
  const [iconIsPressed, setIconIsPressed] = useState(false);
  const { isWidthBelowSmall } = useResize();
  // Check if icontag was clicked and now is filled or not
  const tagBaseStyle = {
    tagBase: cln('self-start justify-center items-center flex-row px-3 border-[1px]', margin),
    textBase: 'font-[sans-400]',
  };

  const tagStyles: TagStyles = {
    filled: {
      tag: {
        size: isMobile ? 'h-11' : 'h-8',
        background: {
          default: 'bg-neutral-950 dark:bg-neutral-50',
          //Hover and active states can be added this way
          hover: 'bg-neutral-100 dark:bg-neutral-600 border-neutral-950 dark:border-neutral-50',
          //active: 'bg-neutral-950 dark:bg-neutral-50',
        },
      },
      text: 'text-neutral-50 dark:text-neutral-950 text-sm',
    },
    border: {
      tag: {
        size: isMobile ? 'h-11  ' : 'h-8',
        background: {
          default: 'border-neutral-950 dark:border-neutral-50',
          hover: 'bg-neutral-200 dark:bg-neutral-600 border-neutral-950 dark:border-neutral-50',
          press: 'bg-neutral-300 dark:bg-neutral-900 border-neutral-950 dark:border-neutral-50',
        },
      },
      text: 'text-neutral-950 dark:text-neutral-50 text-sm',
    },
    iconTag: {
      tag: {
        size: cln('h-12', width),
        background: {
          default: isFilled
            ? 'bg-neutral-950 dark:bg-neutral-50'
            : 'border-neutral-950 dark:border-neutral-50',
          hover: isFilled
            ? 'bg-neutral-900 dark:bg-neutral-100 border-neutral-950 dark:border-neutral-50'
            : 'bg-neutral-200 dark:bg-neutral-600 border-neutral-950 dark:border-neutral-50',
          press: isFilled
            ? 'bg-neutral-500 dark:bg-neutral-200 border-neutral-950 dark:border-neutral-50'
            : 'bg-neutral-300 dark:bg-neutral-900 border-neutral-950 dark:border-neutral-50',
          disabled: 'border-neutral-500',
        },
      },
      text: isFilled
        ? 'text-neutral-50 dark:text-neutral-950'
        : iconTagDisabled
          ? 'text-neutral-500'
          : 'text-neutral-950 dark:text-neutral-50',
    },
    anchor: {
      tag: {
        size: 'h-6',
        background: {
          default: 'border-neutral-950 dark:border-neutral-50',
        },
      },
      text: 'text-neutral-950 dark:text-neutral-50 text-xs',
    },
    selected: {
      tag: {
        size: 'h-[36px] mr-0 mb-0',
        background: {
          default: 'bg-neutral-950 dark:bg-neutral-50 border-r-neutral-50 border-[1px]',
          hover:
            iconIsHovered &&
            'bg-neutral-900 dark:bg-neutral-100 border-r-neutral-950 dark:border-r-neutral-50 border-[1px]',
          press:
            iconIsPressed &&
            'bg-neutral-500 dark:bg-neutral-200 border-r-neutral-950 dark:border-r-neutral-50 border-[1px]',
        },
      },
      text: 'text-neutral-50 dark:text-neutral-950',
    },
  };

  const followTagStyles: FollowTagStyles = {
    size: isWidthBelowSmall || isMobile ? 'h-11 mr-0 mb-0' : 'h-8 mr-0 mb-0',
    tag: {
      filled: cln(
        'bg-neutral-950 dark:bg-neutral-50 border-neutral-950 dark:border-neutral-50',
        isNotified && 'border-l-neutral-50 dark:border-l-neutral-950',
      ),
      hoveredActive: 'bg-neutral-900 dark:bg-neutral-100 border-neutral-950 dark:border-neutral-50',
      hoveredInactive:
        'bg-neutral-200 dark:bg-neutral-600 border-neutral-950 dark:border-neutral-50',
      pressedActive: 'bg-neutral-500 dark:bg-neutral-200 border-neutral-950 dark:border-neutral-50',
      pressedInactive:
        'bg-neutral-300 dark:bg-neutral-900 border-neutral-950 dark:border-neutral-50',
      border: 'border-neutral-950 dark:border-neutral-50',
      disabled: 'bg-neutral-200 dark:bg-neutral-910 border-[0px]',
    },
    text: isFollowed
      ? 'text-neutral-50 dark:text-neutral-950'
      : 'text-neutral-950 dark:text-neutral-50',
    textDisabled: 'text-neutral-300 dark:text-neutral-500',
  };

  //.................
  // REGULAR TAG for filled, border, anchor, icon and selected
  //.................
  const regularTag = (
    <View className={cln(variant === TagVariant.Selected && 'flex-row')}>
      <Pressable
        className={cln(
          tagBaseStyle.tagBase,
          tagStyles[variant]?.tag?.size,
          tagIsHovered
            ? tagStyles[variant]?.tag?.background?.hover
            : tagIsPressed
              ? tagStyles[variant]?.tag?.background?.press
              : iconTagDisabled
                ? tagStyles[variant]?.tag?.background?.disabled
                : tagStyles[variant]?.tag?.background?.default,
        )}
        onPress={onPress}
        onHoverIn={() => {
          if (variant !== TagVariant.Selected) {
            setTagIsHovered(true);
          }
        }}
        onHoverOut={() => setTagIsHovered(false)}
        onPressIn={() => {
          setTagIsHovered(false);
          setTagIsPressed(true);
        }}
        onPressOut={() => setTagIsPressed(false)}
        disabled={iconTagDisabled}
      >
        {variant === TagVariant.IconTag && (
          <Icon
            icon={svgIcons[iconName]}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 24, height: 24 }}
            inverted={isFilled}
            classNames={cln(
              'bottom-px',
              children && 'mr-2.5',
              iconTagDisabled ? 'opacity-50' : 'opacity-100',
            )}
          />
        )}
        <Text className={cln(tagBaseStyle.textBase, tagStyles[variant]?.text)}>{children}</Text>
      </Pressable>
      {variant === TagVariant.Selected && (
        <Pressable
          className={cln(
            tagBaseStyle.tagBase,
            tagStyles[variant]?.tag?.size,
            iconIsHovered
              ? tagStyles[variant]?.tag?.background?.hover
              : iconIsPressed
                ? tagStyles[variant]?.tag?.background?.press
                : tagStyles[variant]?.tag?.background?.default,
          )}
          onPress={onPressRemove}
          onHoverIn={() => setIconIsHovered(true)}
          onHoverOut={() => setIconIsHovered(false)}
          onPressIn={() => {
            setIconIsHovered(false);
            setIconIsPressed(true);
          }}
          onPressOut={() => setIconIsPressed(false)}
        >
          <Icon
            icon={svgIcons.closeIcon}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 24, height: 24 }}
            inverted={true}
          />
        </Pressable>
      )}
    </View>
  );

  let iconStyle;
  let tagStyle;

  // DISABLED
  if (disabled) {
    iconStyle =
      'bg-neutral-200 dark:bg-neutral-910 border-[0px] border-l-[1px] border-l-neutral-50 dark:border-l-neutral-930';
    tagStyle = followTagStyles.tag?.disabled;
  }

  //NOTIFICATION ON
  if (isNotified && !disabled) {
    iconStyle = followTagStyles.tag?.filled;
  }
  //FOLLOWED
  if (isFollowed && !disabled) {
    tagStyle = 'bg-neutral-950 dark:bg-neutral-50 border-neutral-950 dark:border-neutral-50';
  }
  // NOTIFICATION OFF
  if (!isNotified) {
    iconStyle = followTagStyles.tag?.border;
  }
  //NOT FOLLOWED
  if (!isFollowed) {
    tagStyle = followTagStyles.tag?.border;
  }
  // HOVERED when notification off
  if (iconIsHovered && !isNotified) {
    iconStyle = followTagStyles.tag?.hoveredInactive;
  }

  // HOVERED when not followed
  if (tagIsHovered && !isFollowed) {
    tagStyle = followTagStyles.tag?.hoveredInactive;
  }

  // HOVERED when notification on
  //I need to put the border left so it is visible also on hover
  if (iconIsHovered && isNotified) {
    iconStyle = cln(
      followTagStyles.tag?.hoveredActive,
      'border-l-neutral-50 dark:border-l-neutral-950',
    );
  }

  //HOVERED when followed
  if (tagIsHovered && isFollowed) {
    tagStyle = followTagStyles.tag?.hoveredActive;
  }

  //PRESSED when notification on
  if (iconIsPressed && isNotified) {
    iconStyle = followTagStyles.tag?.pressedActive;
  }

  //PRESSED when notification off
  if (iconIsPressed && !isNotified) {
    iconStyle = followTagStyles.tag?.pressedInactive;
  }

  // PRESSED when followed
  if (tagIsPressed && isFollowed) {
    tagStyle = followTagStyles.tag?.pressedActive;
  }

  // PRESSED when not followed
  if (tagIsPressed && !isFollowed) {
    tagStyle = followTagStyles.tag?.pressedInactive;
  }

  // FOLLOW TAG
  const notificationIconSize: { width: number; height: number } = {
    width: 24,
    height: 24,
  };

  const followTag = (
    <View className="flex-row mr-3 mb-3">
      <Pressable
        className={cln(tagBaseStyle.tagBase, followTagStyles.size, tagStyle)}
        onPress={variant === TagVariant.Follow && onPressFollow}
        disabled={disabled}
        onHoverIn={() => setTagIsHovered(true)}
        onHoverOut={() => setTagIsHovered(false)}
        onPressIn={() => setTagIsPressed(true)}
        onPressOut={() => setTagIsPressed(false)}
      >
        <Text
          className={cln(
            tagBaseStyle.textBase,
            disabled ? followTagStyles.textDisabled : followTagStyles.text,
          )}
        >
          {children}
        </Text>
      </Pressable>
      {isFollowed && variant === TagVariant.Follow && (
        <Pressable
          disabled={disabled}
          className={cln(tagBaseStyle.tagBase, followTagStyles.size, iconStyle)}
          style={{
            right: disabled ? 1 : 0,
          }}
          onHoverIn={() => {
            setIconIsHovered(true);
          }}
          onHoverOut={() => {
            setIconIsHovered(false);
          }}
          onPressIn={() => {
            setIconIsPressed(true);
          }}
          onPressOut={() => {
            onPressNotify();
            setIconIsPressed(false);
          }}
        >
          {isNotified ? (
            <Icon
              icon={disabled ? svgIcons.notificationDisabledIcon : svgIcons.notificationOnIcon}
              mobileSize={notificationIconSize}
              webSize={notificationIconSize}
              classNames="left-px bottom-px md:left-0 md:bottom-0"
            />
          ) : (
            <Icon
              icon={svgIcons.notificationOffIcon}
              mobileSize={notificationIconSize}
              webSize={notificationIconSize}
            />
          )}
        </Pressable>
      )}
    </View>
  );

  // Which tag should be rendered based on the variant
  return variant === TagVariant.Follow ? followTag : regularTag;
};

export default Tag;
