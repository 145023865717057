import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../../components/Layout';
import svgIcons, { SvgIcon } from '../../../assets/index';
import { Icon, IconCard } from '../../../components';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import { Role } from '../../../client/interfaces';
import { isMobile, isTablet } from '../../../utils/responsive';
import { cln } from '../../../utils/classnames';
import { NavigationContextStack } from '../../../contexts/NavigationContext/NavigationContextStack';

enum IconCardType {
  article = 'article',
  event = 'event',
  extreme_event = 'extreme-event',
}

interface IconCardInterface {
  type: IconCardType;
  icon: SvgIcon;
  onPress: () => void;
  active: boolean;
}

const AdminIndexScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminIndexScreen>
> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { isAdmin, hasRole, me } = useAuth();
  const [iconCards, setIconCards] = useState<IconCardInterface[]>([]);

  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  const titles: { [key in IconCardType]: string } = {
    [IconCardType.article]: t('navigation:news'),
    [IconCardType.event]: t('navigation:events'),
    [IconCardType.extreme_event]: t('navigation:extreme_events'),
  };

  const updateIconCards = () => {
    const allIconCards = [
      {
        type: IconCardType.article,
        icon: svgIcons.newspaperIcon,
        onPress: () => navigation.navigate(ScreenName.AdminArticleListScreen),
        active: hasRole(Role.publisher),
      },
      {
        type: IconCardType.event,
        icon: svgIcons.calendarCardIcon,
        onPress: () => navigation.navigate(ScreenName.AdminEventListScreen),
        active: hasRole(Role.publisher),
      },
      {
        type: IconCardType.extreme_event,
        icon: svgIcons.extremeEventCalendarIcon,
        onPress: () => navigation.navigate(ScreenName.AdminExtremeEventListScreen),
        active: hasRole(Role.extremeEventAdmin),
      },
    ];
    setIconCards(allIconCards.filter((card) => card.active));
  };

  useEffect(() => {
    updateIconCards();
  }, [me?.roleList]);

  const renderedIconCards = iconCards.map((item, index) => {
    return (
      <IconCard key={index} icon={item.icon} title={titles[item.type]} onPress={item.onPress} />
    );
  });

  return (
    <Layout title={t('navigation:admin_index')} titleMarginBottom={false} isScrollView={!isMobile}>
      <Text
        className={cln(
          'text-neutral-950 dark:text-neutral-50 relative font-[sans-400]',
          !isTablet && isMobile ? 'text-platformSubtitleMobile' : 'text-lead',
        )}
      >
        {t('admin:index:subtitle')}
      </Text>
      {isAdmin ? (
        <ScrollView
          className={cln(!isTablet && isMobile ? 'mt-6' : 'mt-10')}
          showsVerticalScrollIndicator={false}
          overScrollMode="never"
        >
          <View
            className="flex-row flex-wrap"
            style={{
              gap: !isTablet && isMobile ? 16 : 24,
              paddingBottom: !isTablet && isMobile ? 24 : 40,
            }}
          >
            {renderedIconCards}
          </View>
        </ScrollView>
      ) : (
        <View className="flex flex-row justify-center items-center">
          <Icon
            icon={svgIcons.alertIcon}
            mobileSize={{ width: 64, height: 64 }}
            webSize={{ width: 64, height: 64 }}
          />
          <Text className="text-sectionSubtitle text-neutral-950 dark:text-neutral-50 ml-2 font-[sans-400]">
            {t('admin:index:not_authorized')}
          </Text>
        </View>
      )}
    </Layout>
  );
};

export default AdminIndexScreen;
