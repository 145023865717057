import React, { useCallback, useContext } from 'react';
import { Platform } from 'react-native';

import { useFocusEffect } from '@react-navigation/core';
import { Layout } from '../../components';
import {
  default as SectionListWeb,
  default as SectionListNative,
} from '../../components/SectionList/index';
import {
  NavigationParamList,
  ScreenName,
  StackNavigationScreenProps,
} from '../../navigation/types';
import { isNative } from '../../utils/responsive';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const HomeScreen: React.FC<
  StackNavigationScreenProps<NavigationParamList, ScreenName.HomeScreen>
> = ({ navigation, route }) => {
  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  return (
    <Layout isScrollView={!isNative} isHomeScreen={true}>
      {Platform.OS === 'web' ? <SectionListWeb /> : <SectionListNative />}
    </Layout>
  );
};
export default HomeScreen;
