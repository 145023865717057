import { ModuleKey } from '../client/interfaces';
import { Screen, ScreenName } from './types';

const navigationConfig: Screen[] = [
  // Main Navigator
  {
    name: ScreenName.HomeScreen,
    route: '',
    translation: 'navigation:home',
    isTab: true,
  },
  {
    name: ScreenName.ArticlesScreen,
    route: 'feed',
    translation: 'navigation:articles',
    moduleKey: ModuleKey.feed,
    isTab: true,
  },
  {
    name: ScreenName.EventsScreen,
    route: 'events',
    translation: 'navigation:events',
    moduleKey: ModuleKey.event,
    isTab: true,
  },
  {
    name: ScreenName.PostFilterScreen,
    route: 'filter',
    translation: 'navigation:events',
  },
  {
    name: ScreenName.PlatformsScreen,
    route: 'platforms',
    translation: 'navigation:platforms',
    isTab: true,
  },

  // Profile Navigator
  {
    name: ScreenName.AdminIndexScreen,
    route: 'admin',
    translation: 'navigation:admin_index',
    isTab: true,
  },
  {
    name: ScreenName.SettingsScreen,
    route: 'settings',
    translation: 'navigation:settings',
    isTab: true,
  },

  //Screens outside navigator
  {
    name: ScreenName.ProfileScreen,
    route: 'profile',
    translation: 'navigation:profile',
  },
  {
    name: ScreenName.NotificationsScreen,
    route: 'notifications',
    translation: 'navigation:notifications',
  },
  {
    name: ScreenName.ArticleScreen,
    route: 'article',
    translation: 'navigation:article',
  },
  {
    name: ScreenName.EventScreen,
    route: 'event',
    translation: 'navigation:event',
  },
  {
    name: ScreenName.FavoritesScreen,
    route: 'favorites',
    translation: 'navigation:favorites',
    isTab: true,
  },
  {
    name: ScreenName.OnboardingScreen,
    route: 'onboarding',
    translation: 'navigation:onboarding',
    isTab: true,
  },
  {
    name: ScreenName.ErrorScreen,
    route: 'error',
    translation: 'navigation:error',
    isTab: true,
  },
  {
    name: ScreenName.NotFound,
    route: '*',
    translation: 'navigation:error',
  },

  // Admin
  {
    name: ScreenName.AdminArticleListScreen,
    route: 'articles/list',
    translation: 'navigation:admin_article_list',
  },
  {
    name: ScreenName.AdminArticleEditScreen,
    route: 'admin/articles/edit',
    translation: 'navigation:admin_article_edit',
    isTab: true,
  },
  {
    name: ScreenName.AdminExtremeEventListScreen,
    route: 'extreme-events/list',
    translation: 'navigation:admin_extreme_event_list',
  },
  {
    name: ScreenName.AdminExtremeEventEditScreen,
    route: 'admin/extreme-events/edit',
    translation: 'navigation:admin_extreme_event_edit',
    isTab: true,
  },
  {
    name: ScreenName.AdminEventEditScreen,
    route: 'admin/events/edit',
    translation: 'navigation:admin_event_edit',
    isTab: true,
  },
  {
    name: ScreenName.AdminEventListScreen,
    route: 'events/list',
    translation: 'navigation:admin_event_list',
  },
  {
    name: ScreenName.PersonalizationScreen,
    route: 'personalization',
    translation: 'section:personalization',
  },
  {
    name: ScreenName.SplashScreen,
    route: 'splash',
    translation: 'section:personalization',
    isTab: true,
  },
];

export default navigationConfig;
