import React from 'react';
import { Text, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import Layout from '../../components/Layout/index';
import navigationConfig from '../../navigation/navigationConfig';
import {
  ScreenName,
  NavigationParamList,
  Navigator,
  TabNavigationScreenProps,
} from '../../navigation/types';
import { NavItem } from '../../components';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

const ProfileScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.ProfileScreen>
> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();
  const { logout } = useAuth();

  return (
    <Layout isScrollView={false}>
      {navigationConfig.map((navItem) => {
        const navigationDestination = navItem.name.split('/')[1];

        return (
          navItem.name.startsWith(Navigator.profile) &&
          !navItem.name.toLowerCase().includes('admin') && (
            <NavItem
              key={navItem.name}
              onPress={() => navigation.navigate(ScreenName[navigationDestination])}
              title={t(navItem.translation)}
            />
          )
        );
      })}
      <Pressable
        onPress={() => logout()}
        style={{
          borderBottomWidth: 1,
          borderColor: colorScheme === 'dark' ? '#525252' : '#E7E7E7',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: 10,
        }}
      >
        <Text
          style={{
            color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
            fontSize: 16,
            lineHeight: 24,
            fontFamily: 'sans-400',
            fontWeight: '400',
          }}
        >
          {t('common:logout')}
        </Text>
      </Pressable>
    </Layout>
  );
};

export default ProfileScreen;
