import {
  useState,
  useEffect,
  useRef,
  createContext,
  PropsWithChildren,
  useMemo,
  useContext,
} from 'react';
import { Alert, Linking, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { isNative } from '../../utils/responsive';
import {
  NavigationParamList,
  NotificationRedirectScreenName,
  ScreenName,
  StackName,
} from '../../navigation/types';
import { PostType, PushNotificationData } from '../../client/interfaces';
import { useMessage } from '../Messages/MessageContext';
import { useAuth } from '../AuthContext/AuthContext';
import client from '../../client/client';
import { useNotifications } from '../Notification/NotificationContext';
import { useExtremeEvents } from '../ExtremeEvent/ExtremeEventContext';

type PushNotificationContextType = {
  pushToken: string;
  pushNotificationsAreEnabled: boolean;
  onNotificationPress: (postType: PostType, postId: number) => Promise<void>;
  registerForPushNotificationsAsync: () => Promise<string>;
  askForPermissionsAgain: () => void;
};

const pushNotificationContextDefaultValues = {
  pushToken: '',
  pushNotificationsAreEnabled: false,
  onNotificationPress: async () => {
    return;
  },
  registerForPushNotificationsAsync: async () => {
    return '';
  },
  askForPermissionsAgain: () => {
    return;
  },
};

const Context = createContext<PushNotificationContextType>(pushNotificationContextDefaultValues);

export const usePushNotifications = () => useContext(Context);

export function PushNotificationProvider({ children }: Readonly<PropsWithChildren>) {
  const [expoPushToken, setExpoPushToken] = useState<string>('');
  const [pushNotificationsAreEnabled, setPushNotificationsAreEnabled] = useState(false);

  const notificationListener = useRef<Notifications.Subscription>();
  const responseListener = useRef<Notifications.Subscription>();

  const navigation =
    useNavigation<NavigationProp<NavigationParamList, NotificationRedirectScreenName>>();
  const { setMessage } = useMessage();
  const { me, fetchMe } = useAuth();
  const { notifications, fetchNotifications } = useNotifications();
  const { fetchExtremeEvents } = useExtremeEvents();
  const { t } = useTranslation();

  const askForPermissionsAgain = () => {
    Alert.alert(t('alerts:ask_for_permission.title'), t('alerts:ask_for_permission.message'), [
      {
        text: t('alerts:ask_for_permission.cancel'),
        style: 'cancel',
      },
      { text: t('alerts:ask_for_permission.ok'), onPress: () => Linking.openSettings() },
    ]);
  };

  // Push értesítés engedélykérés és token kinyerés
  const registerForPushNotificationsAsync = async (): Promise<string> => {
    let token: string;

    // Android config (app.json -> expo.plugins.expo-notifications) felüldefiniálása
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync({
          ios: {
            allowAlert: true,
            allowBadge: true,
            allowSound: true,
          },
        });
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        return;
      }

      setPushNotificationsAreEnabled(true);

      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        })
      ).data;

      setExpoPushToken(token);
    } else {
      console.log('A push értesítések csak fizikai eszközökön működnek!');
    }

    return token;
  };

  useEffect(() => {
    if (isNative) {
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        }),
      });

      registerForPushNotificationsAsync();

      // This listener is fired whenever a notification is received while the app is foregrounded.
      notificationListener.current = Notifications.addNotificationReceivedListener(
        (notification) => {
          fetchNotifications().catch((error) => {
            console.log({ error, notification });
          });
        },
      );

      // This listener is fired whenever a user taps on or interacts with a notification (works when an app is foregrounded, backgrounded, or killed).
      responseListener.current = Notifications.addNotificationResponseReceivedListener(
        async (response) => {
          const decrementBadgeCount = async () => {
            const badgeCount = await Notifications.getBadgeCountAsync();

            if (badgeCount > 0) {
              await Notifications.setBadgeCountAsync(badgeCount - 1);
            }
          };

          const setNotificationSeen = async (postId: number) => {
            const notificationId = notifications.find((n) => n.postId === postId)?.id;

            if (notificationId) {
              await client.setNotificationsSeen({
                ids: [notificationId],
              });
            }

            await fetchNotifications();
          };

          const data = response.notification.request.content
            .data as unknown as PushNotificationData;
          const relatedPost = data?.post;

          if (relatedPost) {
            decrementBadgeCount().catch((error) => {
              console.error(error.message);
            });

            setNotificationSeen(relatedPost.id).catch((error) => {
              console.error(error.message);
            });

            await onNotificationPress(relatedPost.type, relatedPost.id);
          } else {
            console.log('A push értesítés tartalma nem megfelelő');
          }
        },
      );

      return () => {
        notificationListener.current &&
          Notifications.removeNotificationSubscription(notificationListener.current);
        responseListener.current &&
          Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);

  useEffect(() => {
    const createNewPushToken = async (token: string) => {
      await client.createPushToken(token);
      await fetchMe();
    };

    if (expoPushToken && me && !me?.pushTokens?.includes(expoPushToken)) {
      createNewPushToken(expoPushToken).catch((error) => {
        setMessage({ message: error.message, type: 'error' });
      });
    }
  }, [expoPushToken, me]);

  const onExtremeEventNotificationPress = async (id) => {
    await client.deleteExtremeEventSeen(id);
    await fetchExtremeEvents();

    navigation.navigate(ScreenName.SplashScreen);
    setTimeout(() => {
      navigation.navigate(ScreenName.HomeScreen, {
        screen: ScreenName.HomeStackScreen,
      });
    }, 500);
  };

  const onArticleNotificationPress = async (id) => {
    navigation.navigate(ScreenName.ArticleScreen, { postId: id });
  };

  const onEventNotificationPress = async (id) => {
    navigation.navigate(ScreenName.EventScreen, { postId: id });
  };

  const fallbackNotificationPress = () => {
    navigation.navigate(ScreenName.HomeScreen, {
      screen: ScreenName.HomeStackScreen,
    });
  };

  const onNotificationPress = async (postType: PostType, postId: number): Promise<void> => {
    try {
      switch (postType) {
        case PostType.article:
          await onArticleNotificationPress(postId);
          break;
        case PostType.event:
          await onEventNotificationPress(postId);
          break;
        case PostType.extremeEvent:
          await onExtremeEventNotificationPress(postId);
          break;
        default:
          fallbackNotificationPress();
      }
    } catch (e) {
      console.error(e);
      fallbackNotificationPress();
    }
  };

  const value: PushNotificationContextType = useMemo(
    () => ({
      pushToken: expoPushToken,
      onNotificationPress,
      registerForPushNotificationsAsync,
      pushNotificationsAreEnabled,
      askForPermissionsAgain,
    }),
    [expoPushToken],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
