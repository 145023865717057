import React, { forwardRef } from 'react';
import { View, TextInput } from 'react-native';
import Input, { InputProps } from '../Input/index';
import svgIcons from '../../assets';
import { cln } from '../../utils/classnames';
import { Icon } from '../index';

interface Props extends InputProps {
  errorMessage?: string;
  pickerVisible?: boolean;
}

const DatePickerInput = forwardRef<TextInput, Props>((props, ref) => {
  return (
    <View
      className={cln(props.hidden && 'hidden')}
      style={{ position: 'relative', justifyContent: 'center' }}
    >
      <View style={{ position: 'absolute', right: 12, zIndex: 10 }}>
        <Icon
          icon={svgIcons.calendarIcon}
          mobileSize={{ width: 20, height: 20 }}
          webSize={{ width: 24, height: 24 }}
          isFocused={props.pickerVisible}
        />
      </View>
      <Input
        ref={ref}
        {...props}
        style={{
          cursor: 'pointer',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          outline: 'none',
        }}
        className={cln(
          props.errorMessage && 'text-danger-500',
          props.pickerVisible
            ? 'text-neutral-50 dark:text-neutral-930'
            : 'text-neutral-930 dark:text-neutral-50',
        )}
        inputClassName={cln(
          props.errorMessage && 'border-danger-500',
          props.pickerVisible ? 'bg-neutral-950 dark:bg-neutral-50' : 'bg-none',
        )}
        focusable={false}
        blurOnSubmit={true}
      />
    </View>
  );
});
DatePickerInput.displayName = 'DatePickerInput';
export default DatePickerInput;
