import { Dimensions, Platform } from 'react-native';

export const windowWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('screen').height;
export const isMobileWidth = windowWidth <= 428;
export const isTabletWidth = windowWidth > 500;

export const isMobile = Platform.OS === 'ios' || Platform.OS === 'android' || isMobileWidth;
export const isTablet = (Platform.OS === 'ios' || Platform.OS === 'android') && isTabletWidth;
// Need condition for mobile but only if in browser
export const isMobileWeb = Platform.OS === 'web' && isMobileWidth;

export const isWeb = Platform.OS === 'web';

export const isNative = Platform.OS === 'ios' || Platform.OS === 'android';
export const isIos = Platform.OS === 'ios';
export const isSmallMobile = isMobile && deviceHeight < 750;

// TODO: ez most így nem frissül, érdemes lehet kiszervezni egy hook-ba
export const isWidthBelowMedium = Dimensions.get('window').width < 768;

export const navHeight = isTablet ? 64 : 56;
