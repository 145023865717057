import React, { useContext } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/index';
import { PostFilter, Button } from '../../components';
import { isMobile } from '../../utils/responsive';
import { ArticlesParamsContext } from '../../contexts/ArticlesParams/ArticlesParamsContext';
import { EventsParamsContext } from '../../contexts/EventsParams/EventsParamsContext';
import { ScreenName } from '../../navigation/types';

const PostFilterScreen = ({ route, navigation }) => {
  const { t } = useTranslation();

  // Screen we navigated from
  const fromScreen = route?.params?.fromScreen;

  const { getEventsParams, setGetEventsParams, allEvents, resetInfiniteScroll } =
    useContext(EventsParamsContext);

  const { getArticlesParams, setGetArticlesParams, allArticles } =
    useContext(ArticlesParamsContext);

  const allPosts = fromScreen === ScreenName.EventsScreen ? allEvents : allArticles;

  return (
    <Layout isScrollView={!isMobile}>
      <View
        style={{
          marginBottom: 'auto',
        }}
      >
        <PostFilter
          getPostsParams={
            fromScreen === ScreenName.EventsScreen ? getEventsParams : getArticlesParams
          }
          setGetPostsParams={
            fromScreen === ScreenName.EventsScreen ? setGetEventsParams : setGetArticlesParams
          }
          resetInfiniteScroll={resetInfiniteScroll}
          allPosts={fromScreen === ScreenName.EventsScreen ? allEvents : allArticles}
          screen={fromScreen}
        />
      </View>

      <View
        style={{
          width: '100%',
          bottom: 20,
        }}
      >
        <Button
          text={allPosts?.length < 1 ? t('articles:no_results') : t('articles:view_results')}
          size={'medium'}
          variant={'fill'}
          onPress={() => {
            navigation.goBack();
          }}
          disabled={allPosts?.length < 1}
        />
      </View>
    </Layout>
  );
};

export default PostFilterScreen;
