import React from 'react';
import { Text, View, Pressable, ViewStyle } from 'react-native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { t } from 'i18next';
import dayjs from 'dayjs';
import svgIcons from '../../assets';
import { Icon } from '../index';
import { CalendarCardItem } from '../../client/interfaces';

import { cln } from '../../utils/classnames';
import { getDateDetails } from '../../utils/getDateDetails';
import { addressPattern, handleLinkPress, openInMaps } from '../../utils';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { isMobile } from '../../utils/responsive';

interface Props {
  item?: CalendarCardItem;
  styleProp?: ViewStyle;
}

const CalendarCard: React.FC<Props> = ({ item, styleProp }) => {
  const dateDetails = getDateDetails(item);
  const isTodayEvent =
    dateDetails.startDate === dayjs().format('YYYY-MM-DD') &&
    dateDetails.endDate === dateDetails.startDate;
  const isTomorrowEvent = dateDetails.startDate === dayjs().add(1, 'day').format('YYYY-MM-DD');
  const isTodayOrTomorrow = isTodayEvent || isTomorrowEvent;

  const { setMessage } = useMessage();
  const { showActionSheetWithOptions } = useActionSheet();

  //TODO: Replace with google places validation when available
  const isAddress = addressPattern.test(item.location);

  return (
    <View style={styleProp ? styleProp : { width: '100%' }}>
      {/*Eppen zajlik ful*/}
      {item.shouldShowNow || item.next ? (
        <View className="bg-neutral-100 dark:bg-neutral-930 px-4 py-2 mb-1 self-start h-8">
          <Text
            className={cln(
              item.shouldShowNow
                ? 'text-primary-600 dark:text-primary-500'
                : 'text-neutral-930 dark:text-neutral-50',
              'text-notificationMessage font-[sans-600]',
            )}
          >
            {item.shouldShowNow ? t('today:currently') : t('today:next')}
          </Text>
        </View>
      ) : (
        <View className="h-8 mb-1" />
      )}
      {/*EPPEN ZAJLIK VONAL*/}
      <View
        className={cln(
          item.shouldShowNow || item.shouldShowNowLine
            ? 'bg-primary-600 dark:bg-primary-500'
            : 'bg-neutral-930 dark:bg-neutral-50',
          'h-[3px]',
        )}
      />
      {/*MAIN CONTAINER*/}
      <View
        className={cln(
          'bg-neutral-100 dark:bg-neutral-930 px-4 items-start justify-start w-full flex-1',
          isMobile ? 'pt-[13px] pb-2' : 'pt-[13px] pb-4',
        )}
      >
        {/*DATUM CONTAIENR*/}
        <View className="h-[32px] mb-2">
          {isTodayOrTomorrow ? (
            <View className="flex-row flex-wrap items-start justify-start relative mb-2">
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate mr-2">
                {isTodayEvent ? t('events:today') : t('events:tomorrow')}
              </Text>
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {isTodayOrTomorrow &&
                  `${dateDetails.startTime}${dateDetails.endTime ? '-' + dateDetails.endTime : ''}`}
              </Text>
            </View>
          ) : (
            <View className="flex-row flex-wrap items-start justify-start relative mb-2">
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {dateDetails.dateFirstLine}
                {dateDetails.dateSecondLine ? '-' : ''}
              </Text>
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {dateDetails.dateSecondLine ? dateDetails.dateSecondLine : ''}
              </Text>
            </View>
          )}
        </View>

        {/*TITLE*/}
        <Text
          className="text-neutral-930 dark:text-neutral-50 text-calendarCardTitle mb-4 font-[sans-600]"
          numberOfLines={4}
          selectable={true}
        >
          {item?.name}
        </Text>
        {/*LOCATION AND JOIN LINK CONTAINER*/}
        <View className="items-start mt-auto">
          {/*LOCATION CONTAINER*/}
          {item.location ? (
            <View className={cln(isMobile ? 'mb-[0px]' : 'mb-1')}>
              {isAddress ? (
                <Pressable
                  onPress={() => openInMaps(item.location, showActionSheetWithOptions, setMessage)}
                  className={cln(
                    'items-center flex-row w-full',
                    isMobile ? 'h-[38px]' : 'h-[28px]',
                  )}
                >
                  <Icon
                    icon={svgIcons.pinIconPrimary}
                    mobileSize={{ width: 20, height: 20 }}
                    webSize={{ width: 16, height: 16 }}
                    classNames={'mr-3 bottom-[1px]'}
                  />
                  <View className="flex-1">
                    <Text
                      className="flex-wrap text-primary-600 dark:text-primary-500 text-calendarCardDetails font-[sans-400] underline"
                      numberOfLines={2}
                      selectable={true}
                    >
                      {item.location}
                    </Text>
                  </View>
                </Pressable>
              ) : (
                <View
                  className={cln(
                    'items-center flex-row w-full',
                    isMobile ? 'h-[38px]' : 'h-[28px]',
                  )}
                >
                  <Icon
                    icon={svgIcons.pinIcon}
                    mobileSize={{ width: 20, height: 20 }}
                    webSize={{ width: 16, height: 16 }}
                    classNames={'mr-3 bottom-[1px]'}
                  />
                  <View className="flex-1">
                    <Text
                      className="flex-wrap text-neutral-930 dark:text-neutral-50 text-calendarCardDetails font-[sans-400]"
                      numberOfLines={2}
                      selectable={true}
                    >
                      {item.location}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ) : (
            <View className={cln(isMobile ? 'h-[38px]' : 'h-[28px]')} />
          )}
          {/*JOIN LINK PRESSABLE*/}
          {item.joinLink ? (
            <Pressable
              className={cln('items-center flex-row w-full', isMobile ? 'h-[38px]' : 'h-[16px]')}
              onPress={() => handleLinkPress(item.joinLink)}
            >
              <Icon
                icon={svgIcons.filmIconPrimary}
                mobileSize={{ width: 20, height: 20 }}
                webSize={{ width: 16, height: 16 }}
                classNames={'mr-3'}
              />

              <View className="flex-1">
                <Text className="text-primary-600 dark:text-primary-500 text-calendarCardDetails font-[sans-400] underline">
                  {t('today:join_online')}
                </Text>
              </View>
            </Pressable>
          ) : (
            <View className={cln(isMobile ? 'h-[38px]' : 'h-[16px]')} />
          )}
        </View>
      </View>
    </View>
  );
};

export default CalendarCard;
