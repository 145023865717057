import { Language } from '../resources/interfaces';
import {
  Article,
  Event,
  GetArticlesParams,
  MeResponse,
  Module,
  PaginatedResponse,
  PostModuleOrdersParams,
  Tag,
  TagIdList,
  TagLinkList,
  UserTag,
  LoginResponse,
  NotificationIds,
  Notification,
  ArticleResponse,
  GetSimilarArticlesParams,
  OnboardedUser,
  GetAdminArticlesParams,
  AdminArticleRead,
  GetEventsParams,
  AdminPostReadDetail,
  AdminArticleCreate,
  GetAdminExtremeEventsParams,
  AdminExtremeEventRead,
  ExtremeEvent,
  EventResponse,
  GetSimilarEventsParams,
  AdminExtremeEventCreate,
  AdminEventCreate,
  GetAdminEventsParams,
  AdminEventRead,
  UploadFileResponse,
  CalendarEvent,
  FileModuleType,
} from './interfaces';
import { axiosInstance, iamAxiosInstance } from './axiosInstance';
import { ENDPOINTS, IAM_BE_ENDPOINTS } from './endpoints';

const INCREASED_TIMEOUT = 60 * 1000;

class Client {
  logout = async (): Promise<boolean> => {
    const response = await iamAxiosInstance.post(IAM_BE_ENDPOINTS.LOGOUT);
    return response.data;
  };

  refreshToken = async (): Promise<LoginResponse> => {
    const response = await iamAxiosInstance.post(IAM_BE_ENDPOINTS.REFRESH_TOKEN);
    return response.data;
  };

  getArticles = async (params: GetArticlesParams): Promise<PaginatedResponse<Article>> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ARTICLES}`, {
      params,
    });

    return response.data;
  };

  getEvents = async (params: GetEventsParams): Promise<PaginatedResponse<Event>> => {
    const response = await axiosInstance.get(`${ENDPOINTS.EVENTS}`, {
      params,
    });

    return response.data;
  };

  getExtremeEventUnseen = async (): Promise<ExtremeEvent[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.EXTREME_EVENT_UNSEEN}`);
    return response.data;
  };

  createExtremeEventSeen = async (extremeEventId: number): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.EXTREME_EVENT_SEEN}/${extremeEventId}`);
  };

  deleteExtremeEventSeen = async (postId: number): Promise<void> => {
    await axiosInstance.delete(`${ENDPOINTS.EXTREME_EVENT_SEEN}/${postId}`);
  };

  getHighlightedArticles = async (): Promise<Article[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.HIGHLIGHTED_ARTICLES}`);

    return response.data;
  };

  getArticle = async (postId: number): Promise<ArticleResponse> => {
    const response = await axiosInstance.get(`${ENDPOINTS.POSTS}/${postId}`);
    return response.data;
  };

  getEvent = async (postId: number): Promise<EventResponse> => {
    const response = await axiosInstance.get(`${ENDPOINTS.POSTS}/${postId}`);
    return response.data;
  };

  getSimilarArticles = async (params: GetSimilarArticlesParams): Promise<Article[]> => {
    const response = await axiosInstance.get(ENDPOINTS.SIMILAR_ARTICLES, { params });
    return response.data;
  };

  getSimilarEvents = async (params: GetSimilarEventsParams): Promise<Event[]> => {
    const response = await axiosInstance.get(ENDPOINTS.SIMILAR_EVENTS, { params });
    return response.data;
  };

  getAllTags = async (): Promise<Tag[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.TAGS}`);
    return response.data;
  };

  getUserTags = async (): Promise<UserTag[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.TAGS}/user`);
    return response.data;
  };

  getNotifications = async (): Promise<Notification[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.NOTIFICATIONS}`);
    return response.data;
  };
  setNotificationsSeen = async (notificationIds: NotificationIds): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.NOTIFICATIONS_SEEN}`, notificationIds);
  };

  createPostBookmark = async (postId: number): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.BOOKMARKED}/${postId}`);
  };

  deletePostBookmark = async (postId: number): Promise<void> => {
    await axiosInstance.delete(`${ENDPOINTS.BOOKMARKED}/${postId}`);
  };

  deleteTag = async (tagIds: TagIdList): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.TAG_DELETE}`, tagIds);
  };

  createTag = async (params: TagLinkList): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.TAGS}`, params);
  };

  getModules = async (): Promise<Module[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.MODULES}`);
    return response.data;
  };

  changeModulesOrder = async (params: PostModuleOrdersParams): Promise<void> => {
    await axiosInstance.post(`${ENDPOINTS.MODULES_ORDER}`, params);
  };

  getMe = async (): Promise<MeResponse> => {
    const response = await axiosInstance.get(`${ENDPOINTS.USERS_ME}`);
    return response.data;
  };

  setOnboarded = async () => {
    await axiosInstance.post(`${ENDPOINTS.SET_ONBOARDED}`);
  };

  createPushToken = async (pushToken: string): Promise<Article[]> => {
    const response = await axiosInstance.post(ENDPOINTS.CREATE_PUSH_TOKEN, null, {
      params: { pushToken },
    });
    return response.data;
  };

  saveUserLanguage = async (language: Language): Promise<OnboardedUser> => {
    const response = await axiosInstance.post(ENDPOINTS.SAVE_USER_LANGUAGE, null, {
      params: { language },
    });
    return response.data;
  };

  getAdminArticles = async (
    params: GetAdminArticlesParams,
  ): Promise<PaginatedResponse<AdminArticleRead>> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_ARTICLES}`, {
      params,
    });

    return response.data;
  };

  getAdminArticle = async (postId: number): Promise<AdminPostReadDetail> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_ARTICLES}/${postId}`);

    return response.data;
  };

  getAdminExtremeEvent = async (postId: number): Promise<AdminPostReadDetail> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_EXTREME_EVENTS}/${postId}`);

    return response.data;
  };

  getAdminEvent = async (postId: number): Promise<AdminPostReadDetail> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_EVENTS}/${postId}`);

    return response.data;
  };

  createArticle = async (article: AdminArticleCreate): Promise<void> => {
    const response = await axiosInstance.post(`${ENDPOINTS.ADMIN_ARTICLES}`, article);

    return response.data;
  };

  updateArticle = async (postId: number, article: AdminArticleCreate): Promise<void> => {
    const response = await axiosInstance.patch(`${ENDPOINTS.ADMIN_ARTICLES}/${postId}`, article);

    return response.data;
  };

  createExtremeEvent = async (extremeEvent: AdminExtremeEventCreate): Promise<void> => {
    const response = await axiosInstance.post(`${ENDPOINTS.ADMIN_EXTREME_EVENTS}`, extremeEvent);

    return response.data;
  };

  updateExtremeEvent = async (
    postId: number,
    extremeEvent: AdminExtremeEventCreate,
  ): Promise<void> => {
    const response = await axiosInstance.patch(
      `${ENDPOINTS.ADMIN_EXTREME_EVENTS}/${postId}`,
      extremeEvent,
    );

    return response.data;
  };

  createEvent = async (event: AdminEventCreate): Promise<void> => {
    const response = await axiosInstance.post(`${ENDPOINTS.ADMIN_EVENTS}`, event);

    return response.data;
  };

  updateEvent = async (postId: number, event: AdminEventCreate): Promise<void> => {
    const response = await axiosInstance.patch(`${ENDPOINTS.ADMIN_EVENTS}/${postId}`, event);

    return response.data;
  };

  getAdminExtremeEvents = async (
    params: GetAdminExtremeEventsParams,
  ): Promise<PaginatedResponse<AdminExtremeEventRead>> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_EXTREME_EVENTS}`, {
      params,
    });

    return response.data;
  };

  getAdminEvents = async (
    params: GetAdminEventsParams,
  ): Promise<PaginatedResponse<AdminEventRead>> => {
    const response = await axiosInstance.get(`${ENDPOINTS.ADMIN_EVENTS}`, {
      params,
    });

    return response.data;
  };

  getCalendarEvents = async (): Promise<CalendarEvent[]> => {
    const response = await axiosInstance.get(`${ENDPOINTS.CALENDAR_EVENTS}`);
    return response.data;
  };

  deletePost = async (postId: number): Promise<void> => {
    await axiosInstance.delete(`${ENDPOINTS.ADMIN_POSTS}/${postId}`);
  };

  getFileUploadUrl = async (
    module: FileModuleType,
    controller: AbortController,
  ): Promise<UploadFileResponse> => {
    const response = await axiosInstance.post(`${ENDPOINTS.ADMIN_FILES}`, null, {
      params: { module },
      signal: controller.signal,
    });
    return response.data;
  };

  deleteFile = async (fileId: number): Promise<void> => {
    await axiosInstance.delete(`${ENDPOINTS.ADMIN_FILES}/${fileId}`);
  };
}

export default new Client();
