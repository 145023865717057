import React, { useCallback, useContext, useRef, useState } from 'react';
import { Text, LayoutAnimation, Platform, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { motion } from 'framer-motion';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../components/Layout/index';
import { DragDropItem } from '../../components';
import {
  NavigationParamList,
  ScreenName,
  StackNavigationScreenProps,
} from '../../navigation/types';
import { cln } from '../../utils/classnames';
import { isMobile, isNative, isTablet } from '../../utils/responsive';
import { useModules } from '../../contexts/Module/ModuleContext';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const PersonalizationScreen: React.FC<
  StackNavigationScreenProps<NavigationParamList, ScreenName.PersonalizationScreen>
> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { modules, setModules } = useModules();

  const dragItem = useRef(null);
  const dragNode = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);

  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  function enableIsDraggable() {
    setIsDraggable(true);
  }

  function handleDragStart(e, itemIndex) {
    if (isDraggable) {
      dragItem.current = itemIndex;
      dragNode.current = e.target;
      dragNode.current.addEventListener('dragend', handleDragEnd);
      setTimeout(() => setIsDragging(true), 0);
    }
  }

  function handleDragEnd() {
    dragNode.current.removeEventListener('dragend', handleDragEnd);
    dragItem.current = null;
    dragNode.current = null;
    setIsDragging(false);
    setIsDraggable(false);
  }

  const handleDragEnter = (e, targetItem) => {
    if (dragItem.current !== e.target) {
      setModules((prevModules) => {
        const newList = [...prevModules];
        const draggedItem = newList[dragItem.current];
        newList.splice(dragItem.current, 1);
        newList.splice(targetItem, 0, draggedItem);
        dragItem.current = targetItem;
        return newList;
      });
    }
  };

  function getStyles(itemIndex) {
    if (dragItem.current === itemIndex) {
      return { opacity: 0 };
    } else {
      return { opacity: 1 };
    }
  }

  function moveItem(section, direction) {
    if (Platform.OS === 'ios') {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
    setModules((prevModules) => {
      // Index of moved item
      const index = prevModules.findIndex((module) => module.id === section.id);

      // if index is valid
      if (index >= 0) {
        const updatedModules = [...prevModules];

        // New index based on direction
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        // if new index valid
        if (
          (direction === 'down' && index < modules.length - 1) ||
          (direction === 'up' && index > 0)
        ) {
          [updatedModules[index], updatedModules[newIndex]] = [
            updatedModules[newIndex],
            updatedModules[index],
          ];

          return updatedModules;
        }
      }
      return prevModules;
    });
  }

  const renderedElementsWeb = !isNative
    ? modules.map((section, itemIndex) => {
        const isFirstItem = itemIndex === 0;
        const isLastItem = itemIndex === modules.length - 1;

        return (
          <motion.div
            key={section.key}
            layout
            transition={{ type: 'tween', stiffness: 300, damping: 40 }}
          >
            <DragDropItem
              containerStyle={isDragging ? getStyles(itemIndex) : { opacity: 1 }}
              sectionTitle={t(`section:${section.key}`)}
              key={section.key}
              draggable={isDraggable}
              onDragStart={(e) => handleDragStart(e, itemIndex)}
              onDragEnter={isDragging ? (e) => handleDragEnter(e, itemIndex) : null}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onMouseDown={enableIsDraggable}
              isFirstItem={isFirstItem}
              isLastItem={isLastItem}
              handleUpPress={() => moveItem(section, 'up')}
              handleDownPress={() => moveItem(section, 'down')}
            />
          </motion.div>
        );
      })
    : null;

  const renderElementsMobile = ({ item, drag, getIndex }) => {
    const index = getIndex();
    const isFirstItem = index === 0;
    const isLastItem = index === modules.length - 1;

    return (
      <DragDropItem
        key={item.key}
        sectionTitle={t(`section:${item.key}`)}
        handleDrag={drag}
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        handleUpPress={() => {
          if (!isFirstItem) {
            moveItem(item, 'up');
          }
        }}
        handleDownPress={() => {
          if (!isLastItem) {
            moveItem(item, 'down');
          }
        }}
      />
    );
  };

  return (
    <Layout title={!isMobile && t('section:personalization')} isScrollView={false}>
      <Text
        className={cln(
          'text-neutral-950 dark:text-neutral-50 relative font-[sans-600]',
          !isTablet && isMobile ? 'text-cardTitleMobile' : 'text-sectionSubtitle',
        )}
      >
        {t('personalization:subtitle')}
      </Text>
      {isNative ? (
        <DraggableFlatList
          data={modules}
          showsVerticalScrollIndicator={false}
          automaticallyAdjustContentInsets={false}
          scrollEnabled={false}
          keyExtractor={(item) => item.key}
          renderItem={renderElementsMobile}
          onDragEnd={({ data }) => {
            setModules(data);
          }}
          containerStyle={{ marginTop: 24 }}
        />
      ) : (
        <View className="mt-6">{renderedElementsWeb}</View>
      )}
    </Layout>
  );
};

export default PersonalizationScreen;
