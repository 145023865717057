import { Text, View, Animated, StyleSheet, Platform, Easing, Dimensions } from 'react-native';
import React, { useRef, useState, useEffect } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import MaskedView from '@react-native-masked-view/masked-view';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import { isMobile, isNative } from '../../utils/responsive';

const windowWidth = Dimensions.get('window').width;
const fontSizeValue = windowWidth > 350 ? 43 : 34;
// TODO: Legyenek konfigurálhatóak a szövegek
const wordsKeys = ['interactive', 'automated', 'smart', 'mome'];

const AnimatedText: React.FC = () => {
  const animatingValueWeb = fontSizeValue + 4;
  const subTitleSize = windowWidth > 350 ? 'text-animSubtitle' : 'text-animSubtitleSmall';
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();

  const [words, setWords] = useState<string[]>(wordsKeys);

  const textAnimRef = useRef<Animated.Value>(new Animated.Value(0)).current;

  useEffect(() => {
    function textAnimation() {
      Animated.timing(textAnimRef, {
        toValue:
          Platform.OS === 'web' && navigator.appVersion.indexOf('Win') != -1
            ? -animatingValueWeb - 12
            : Platform.OS === 'web'
              ? -animatingValueWeb
              : -fontSizeValue,
        duration: 600,
        useNativeDriver: true,
        easing: Easing.bezier(0.68, -0.6, 0.32, 1.6),
      }).start(() => {
        setWords((prevWords) => [...prevWords.slice(1), prevWords[0]]);
        textAnimRef.setValue(0);
      });
    }

    const intervalId = setInterval(textAnimation, 1400);
    return () => clearInterval(intervalId);
  }, []);

  // TODO: We need a gradient for text for the light theme
  return (
    <View
      className={`items-center justify-center w-full bg-neutral-10 dark:bg-neutral-950 flex-column flex-column`}
      style={{ marginTop: isNative ? 0 : -80 }}
    >
      <Text
        className={`text-neutral-950 dark:text-neutral-50 ${subTitleSize} pt-1 font-[sans-600] text-right`}
      >
        {t('onboarding:animated:welcome')}
      </Text>
      <View
        style={styles.hiddenContainer}
        className={`overflow-hidden mt-[10px] mb-4 ${
          Platform.OS === 'web' ? 'mx-4 w-370' : 'w-full'
        }`}
      >
        <Animated.View
          style={{
            transform: [{ translateY: textAnimRef }],
            bottom: Platform.OS === 'web' && navigator.appVersion.indexOf('Win') != -1 ? 4 : 0,
          }}
        >
          {Platform.OS === 'ios' || Platform.OS === 'android'
            ? words.map((word) => (
                <MaskedView
                  key={word}
                  maskElement={
                    <Text
                      className="text-center font-[sans-700]"
                      style={{
                        fontSize: fontSizeValue,
                        lineHeight: fontSizeValue + 6,
                      }}
                    >
                      {t(`onboarding:animated:${word}`)}
                    </Text>
                  }
                >
                  <LinearGradient
                    colors={
                      colorScheme === 'dark' ? ['#1fffd7', '#edf115'] : ['#1FFFD7', '#623BFF']
                    }
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <Text
                      className="text-center font-[sans-700] opacity-0"
                      style={{
                        fontSize: fontSizeValue,
                        lineHeight: fontSizeValue,
                      }}
                    >
                      {t(`onboarding:animated:${word}`)}
                    </Text>
                  </LinearGradient>
                </MaskedView>
              ))
            : words.map((word) => (
                <div
                  key={word}
                  style={{
                    fontSize: fontSizeValue,
                    marginBottom: navigator.appVersion.indexOf('Win') != -1 ? 8 : 4,
                    textAlign: 'center',
                    fontFamily: 'sans-700',
                    background:
                      colorScheme === 'dark'
                        ? 'linear-gradient(to right, #1fffd7, #edf115)'
                        : 'linear-gradient(to right, #1fffd7, #623BFF)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                  }}
                >
                  {t(`onboarding:animated:${word}`)}
                </div>
              ))}
        </Animated.View>
      </View>
      <Text
        className={`text-neutral-950 dark:text-neutral-50 ${subTitleSize} font-[sans-600]`}
        style={{ textAlign: isMobile ? 'center' : 'left' }}
      >
        {t('onboarding:animated:ulp')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  hiddenContainer: {
    height: fontSizeValue,
  },
});

export default AnimatedText;
