import React, { useCallback, useState } from 'react';
import { Text, ScrollView, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { useColorScheme } from 'nativewind';
import Layout from '../../components/Layout/index';
import { Notification } from '../../components';
import { useNotifications } from '../../contexts/Notification/NotificationContext';
import { isMobile, isNative } from '../../utils/responsive';

const NotificationsScreen = ({ onPressBackButton }) => {
  const { t } = useTranslation();
  const { notifications, fetchNotifications, timeAgoCalc, setNotificationsSeen } =
    useNotifications();
  const [refreshing, setRefreshing] = useState(false);
  const { colorScheme } = useColorScheme();

  async function fetchingRendering() {
    await fetchNotifications();
    timeAgoCalc();
  }

  useFocusEffect(
    useCallback(() => {
      fetchingRendering();
      return () => {
        seenNotification();
      };
    }, []),
  );

  const seenNotification = async () => {
    if (notifications?.some((notification) => !notification.seenAtUtc)) {
      await setNotificationsSeen();
      fetchNotifications();
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchingRendering();
    setTimeout(() => {
      setRefreshing(false);
    }, 800);
  }, []);

  return (
    <Layout isScrollView={false} isListScreen={true}>
      <ScrollView
        contentContainerStyle={{
          alignItems: notifications?.length > 0 ? 'flex-start' : 'center',
          justifyContent: notifications?.length > 0 ? 'flex-start' : 'center',
          paddingBottom: 24,
          paddingHorizontal: 30,
          height: notifications.length === 0 ? '100%' : null,
        }}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            progressViewOffset={-10}
            tintColor={colorScheme === 'dark' ? '#b0b0b0' : '#d1d1d1'}
            colors={colorScheme === 'dark' ? ['#b0b0b0'] : ['#f6f6f6']}
            progressBackgroundColor={
              colorScheme === 'dark' ? 'rgba(79,79,79,0.9)' : 'rgba(136, 136, 136,0.9)'
            }
          />
        }
      >
        {notifications?.length > 0 ? (
          notifications?.map((notification) => {
            return <Notification key={notification.id} notificationData={notification} />;
          })
        ) : (
          <>
            <Text className="font-[sans-400] text-primaryLight-500 dark:text-primary-500 text-lead">
              {t('notifications:no_new_notifications')},
            </Text>
            <Text className="font-[sans-400] text-neutral-950 dark:text-neutral-50 text-lead">
              {t('notifications:check_back_later')}
            </Text>
          </>
        )}
      </ScrollView>
    </Layout>
  );
};

export default NotificationsScreen;
