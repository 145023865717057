import React, { useState } from 'react';
import { View, Text, Pressable, GestureResponderEvent } from 'react-native';
import { SvgIcon } from '../../assets';
import Icon from '../Icon';
import { cln } from '../../utils/classnames';
import { isMobile, isTablet } from '../../utils/responsive';

interface Props {
  icon: SvgIcon;
  title: string;
  onPress: (event: GestureResponderEvent) => void;
  cardWidth?: number;
}

const IconCard: React.FC<Props> = ({ icon, title, onPress, cardWidth }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const styles = {
    base: cln(!isTablet && isMobile ? 'px-3 py-4 h-[136px]' : 'h-[200px] pb-5 pr-5 pl-5 pt-4'),
    default: 'border-2 border-neutral-950 dark:border-neutral-50',
    hovered:
      'border-2 bg-neutral-200 dark:bg-neutral-700 border-neutral-950 dark:border-neutral-50',
    pressed:
      'border-[3px] bg-neutral-300 dark:bg-neutral-930 border-neutral-950 dark:border-neutral-50',
  };

  let finalStyle = styles.default;

  if (true === isActive) {
    finalStyle = styles.pressed;
  } else if (true === isHovered || isFocused) {
    finalStyle = styles.hovered;
  }

  return (
    <View
      className={cln(styles.base, finalStyle)}
      style={{ width: !isTablet && isMobile ? cardWidth : 200 }}
    >
      <Pressable
        className="flex-1"
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => {
          setIsFocused(false);
          setIsHovered(false);
        }}
        onPress={onPress}
        onPressIn={() => setIsActive(true)}
        onPressOut={() => setIsActive(false)}
      >
        <View
          className="items-center"
          style={{
            top: isTablet ? '13%' : '9%',
          }}
        >
          <Icon
            icon={icon}
            mobileSize={{ width: isTablet ? 64 : 48, height: isTablet ? 64 : 48 }}
            webSize={{ width: 80, height: 80 }}
          />

          <Text
            className={cln(
              'text-center text-neutral-950 dark:text-neutral-50 font-[sans-600]',
              !isTablet && isMobile ? 'mt-2 text-xs' : 'mt-4 text-iconCardTitle',
            )}
          >
            {title}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default IconCard;
