import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName } from '../types';
import { FavoritesScreen, NotificationsScreen, ProfileScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';
import SettingsStackNavigator from './SettingsStackNavigator';

const HeaderStack = createStackNavigator();

export default function HeaderStackNavigator() {
  const { t } = useTranslation();
  return (
    <HeaderStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        headerShown: false,
      }}
    >
      <HeaderStack.Screen
        name={ScreenName.NotificationsScreen}
        component={NotificationsScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:notifications')} />
              );
            },
          };
        }}
      />
      <HeaderStack.Screen
        name={ScreenName.ProfileScreen}
        component={ProfileScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} headerTitle={t('navigation:profile')} />;
            },
          };
        }}
      />
      <HeaderStack.Screen
        name={ScreenName.SettingsScreen}
        component={SettingsStackNavigator}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: false,
            header: () => {
              return <StackHeader navigation={navigation} headerTitle={t('section:settings')} />;
            },
            title: t('section:settings'),
          };
        }}
      />
      <HeaderStack.Screen
        name={ScreenName.FavoritesScreen}
        component={FavoritesScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:favorites')} />
              );
            },
            title: t('navigation:favorites'),
          };
        }}
      />
    </HeaderStack.Navigator>
  );
}
