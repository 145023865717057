import React, { forwardRef, useEffect, useState } from 'react';
import { View, Pressable, Text, TextInput } from 'react-native';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { cln } from '../../utils/classnames';
import useOutsideClickHandler from '../../hooks/useOnClickOutside';
import {
  MONTH,
  formatToISODate,
  getTwoDigitString,
  formatMonthPickerLabelText,
  formatMonthStepperLabelText,
  formatYearStepperLabelText,
  formatDecadeStepperLabelText,
  getDaysOfMonth,
  getFirstDay,
  getStartOfDecade,
  getEndOfDecade,
  getLastMonth,
  getLastYear,
  getNextMonth,
  getNextYear,
  isToday,
  changeDate,
  dayNames,
} from '../../utils/DatePicker';
import { InputProps } from '../Input';
import { isWeb } from '../../utils/responsive';
import DatePickerStepper from './DatePickerStepper';
import TimePicker from './TimePicker';
import DatePickerInput from './DatePickerInput';

export type DatePickerMode = 'day' | 'month' | 'year';
export type DateTimeType = 'date' | 'datetime-local';

export interface CustomDatePickerProps extends InputProps {
  type?: DateTimeType;
  minDate?: Date;
  maxDate?: Date;
  todayText?: string;
  disabled?: boolean;
  setValue(value: string): void;
  value?: string;
  startHour?: number;
  endHour?: number;
  stepMinutes?: number;
  lang?: 'hu' | 'en';
  portalTo?: HTMLElement;
  styleProp?: number | string;
  name: string;
  hideInput?: boolean;
  isModal?: boolean;
  errorMessage?: string;
}

const CustomDatePicker = forwardRef<TextInput, CustomDatePickerProps>(
  (
    {
      type = 'date',
      minDate = new Date('1900-01-01'),
      maxDate = new Date('2100-12-31'),
      todayText = 'Today',
      startHour = 0,
      endHour = 23,
      stepMinutes = 60,
      disabled,
      value = new Date(),
      setValue,
      name,
      lang = 'hu',
      portalTo = null,
      styleProp,
      hideInput = false,
      isModal,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const inputRef = ref;

    const [selectedDate, setSelectedDate] = useState<Date>(new Date(value));
    const [shownDate, setShownDate] = useState<Date>(new Date(value));
    const [inputValue, setInputValue] = useState<string>('');
    const [pickerMode, setPickerMode] = useState<DatePickerMode>('day');
    const { visible, setVisible, reference } = useOutsideClickHandler(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const { t, i18n } = useTranslation();

    const handleHoverIn = (day) => {
      setHoveredItem(`${day.day}-${day.disabled ? 'disabled' : 'regular'}`);
    };

    const handleHoverOut = () => {
      setHoveredItem(null);
    };

    useEffect(() => {
      const initialDate = value ? new Date(value.toString()) : new Date();
      if (value && initialDate.getMinutes() % 10) {
        initialDate.setMinutes(0);
      }

      setSelectedDate(initialDate);
    }, [value]);

    useEffect(() => {
      const formatToInputText = (date: Date): string => {
        const subStringTo = type === 'datetime-local' ? 16 : 10;
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const localDate = new Date(date - timezoneOffset);
        return localDate.toISOString().substring(0, subStringTo);
      };

      if (selectedDate) {
        const newDateString = selectedDate.toISOString();
        const newDate = new Date(newDateString);
        setInputValue(formatToInputText(newDate));
        setValue(formatToInputText(newDate));
        setShownDate(newDate);
        setVisible(false);
      }
    }, [selectedDate]);

    const handleOnClick = (e) => {
      e.preventDefault();
      if (disabled) return;
      setVisible(true);
    };

    const isSelectedMonth = (date: Date): boolean => {
      if (!selectedDate) return false;
      return (
        date.getMonth() === selectedDate.getMonth() &&
        date.getFullYear() === selectedDate.getFullYear()
      );
    };

    const rowsForMonth = (firstDayOfMonthDate: Date, daysOfMonth: number): number => {
      let rows = 0;
      let i = 0;

      // Loop through each day of the month
      while (i < daysOfMonth) {
        const iterDate = new Date(firstDayOfMonthDate);
        iterDate.setDate(iterDate.getDate() + i);

        // If the current day is Saturday or the last day of the month
        if (iterDate.getDay() === 0 || i === daysOfMonth - 1) {
          rows++;
        }
        i++;
      }

      return rows;
    };

    const generateDayTable = () => {
      const firstDayOfMonthDate = getFirstDay(shownDate);
      const firstDayIndex = firstDayOfMonthDate.getDay();
      const daysOfSelectedMonth = getDaysOfMonth(shownDate);
      const dayOfTheWeekIndex = firstDayIndex === 0 ? 7 : firstDayIndex;
      const selectedDateISOFormat = selectedDate ? formatToISODate(selectedDate) : null;
      const lastMonth = getLastMonth(shownDate, minDate);
      const daysOfLastMonth = getDaysOfMonth(lastMonth);
      const rows = rowsForMonth(firstDayOfMonthDate, daysOfSelectedMonth);
      const cols = 7;

      let previousMonthStepper = dayOfTheWeekIndex - 1;
      let dayIndex =
        previousMonthStepper > 0
          ? daysOfLastMonth - previousMonthStepper + 1
          : firstDayOfMonthDate.getDate();
      let dateIndex = previousMonthStepper > 0 ? lastMonth : firstDayOfMonthDate;
      dateIndex.setDate(dayIndex);

      const dayTable = [];
      let disabledDate = !!previousMonthStepper;

      for (let row = 1; row <= rows; row++) {
        const oneRow = [];
        for (let col = 1; col <= cols; col++) {
          if (dayIndex > daysOfLastMonth && previousMonthStepper === 0) {
            dayIndex = 1;
            dateIndex = getNextMonth(dateIndex, maxDate);
            disabledDate = false;
          }

          if (dayIndex > daysOfSelectedMonth && previousMonthStepper < 0) {
            dayIndex = 1;
            dateIndex = getNextMonth(dateIndex, maxDate);
            disabledDate = true;
          }

          dateIndex.setDate(dayIndex);
          const formattedDate = formatToISODate(dateIndex);

          const isDateBetween = (): boolean => {
            return (
              dateIndex.getDate() >= minDate.getDate() && dateIndex.getDate() <= maxDate.getDate()
            );
          };

          oneRow.push({
            day: dayIndex,
            date: formattedDate,
            isToday: isToday(dateIndex),
            disabled: disabledDate || !isDateBetween(),
            isSelected: formattedDate === selectedDateISOFormat,
          });
          previousMonthStepper--;
          dayIndex++;
        }
        dayTable.push(oneRow);
      }

      return dayTable;
    };

    const generateYearTable = () => {
      const startOfDecade = getStartOfDecade(shownDate);
      const endOfDecade = getEndOfDecade(shownDate);
      const years = [];

      for (let year = startOfDecade.getFullYear() - 1; year <= endOfDecade.getFullYear(); year++) {
        years.push({
          year,
          isSelected: selectedDate
            ? selectedDate.getFullYear() === year
            : shownDate.getFullYear() === year,
        });
      }

      return years;
    };

    const generateTimeTable = () => {
      const stepper = 60 / stepMinutes;
      const hours = [];
      const selectedHours = selectedDate ? selectedDate.getHours() : shownDate.getHours();
      const selectedMinutes = selectedDate ? selectedDate.getMinutes() : shownDate.getMinutes();

      for (let hour = startHour; hour <= endHour; hour++) {
        for (let step = 0; step < stepper; step++) {
          const minutes = stepMinutes * step;
          hours.push({
            stringFormat: `${getTwoDigitString(hour)}:${getTwoDigitString(minutes)}`,
            isSelected: hour === selectedHours && minutes === selectedMinutes,
            hour,
            minutes,
          });
        }
      }

      return hours;
    };

    const handleDayClick = (event, day) => {
      if (day.disabled) {
        return;
      }

      const date = new Date(day.date);
      const hours = selectedDate ? selectedDate.getHours() : shownDate.getHours();
      const minutes = selectedDate ? selectedDate.getMinutes() : shownDate.getMinutes();
      date.setHours(hours);
      date.setMinutes(minutes);
      setSelectedDate(date);
    };

    const handleMonthClick = (date: Date) => {
      setShownDate(date);
      setPickerMode('day');
    };

    const handleYearClick = (year: number) => {
      shownDate.setFullYear(year);
      setShownDate(shownDate);
      setPickerMode('month');
    };

    const handleMonthStepperClick = () => {
      setPickerMode('month');
    };

    const handleYearStepperClick = () => {
      setPickerMode('year');
    };

    const handleSelectToday = () => {
      const now = new Date();
      now.setMinutes(0);
      setShownDate(now);
      setSelectedDate(now);
    };

    const handleSelectTime = (hours: number, minutes: number) => {
      const baseDate = selectedDate ? selectedDate.toString() : shownDate.toString();
      const newDate = new Date(baseDate);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      setSelectedDate(newDate);
    };

    const renderDayNames = () => {
      return (
        <View
          className="flex-row justify-around items-center border-neutral-930 dark:border-neutral-50"
          style={{ width: '100%', borderBottomWidth: 1 }}
        >
          {dayNames[i18n.language].map((dayName) => {
            return (
              <View
                className="flex-1 h-[50px] flex justify-center items-center"
                key={`${name}-day-${dayName}`}
              >
                <Text className="text-neutral-930 dark:text-neutral-50">{dayName}</Text>
              </View>
            );
          })}
        </View>
      );
    };
    const renderDays = () => {
      const dayTable = generateDayTable();

      return dayTable.map((weeks, index) => {
        return (
          <View
            className="flex-row flex-wrap justify-between"
            key={`${name}-week-${index}`}
            style={{ width: '100%' }}
          >
            {weeks.map((day) => {
              const dayKey = `${day.day}-${day.disabled ? 'disabled' : 'regular'}`;
              const isHovered = hoveredItem === dayKey;
              return (
                <Pressable
                  className={cln(
                    'cursor-pointer flex-1 h-[50px] flex justify-center items-center',
                    day.isSelected && 'bg-primary-600 dark:bg-primary-500',
                    isHovered && !day.isSelected && 'bg-neutral-200 dark:bg-neutral-900',
                    isHovered && day.isSelected && 'bg-primaryLight-300 dark:bg-primary-300',
                  )}
                  key={`${name}-day-${day.day}`}
                  onPress={(e) => handleDayClick(e, day)}
                  onHoverIn={() => handleHoverIn(day)}
                  onHoverOut={handleHoverOut}
                >
                  <Text
                    className={cln(
                      day.isSelected && 'text-neutral-50 dark:text-neutral-930',
                      day.isToday && !day.isSelected && 'text-primary-600 dark:text-primary-500',
                      !day.isToday && !day.isSelected && 'text-neutral-930 dark:text-neutral-50',
                      day.disabled && 'text-neutral-500 dark:text-neutral-500',
                    )}
                  >
                    {day.day}
                  </Text>
                </Pressable>
              );
            })}
          </View>
        );
      });
    };

    const renderMonths = () => {
      return (
        <View className="flex-row flex-wrap justify-between" style={{ width: '100%' }}>
          {Object.keys(MONTH).map((month) => {
            const monthDate = new Date(shownDate.getFullYear(), MONTH[month]);

            return (
              <Pressable
                className={cln(
                  'cursor-pointer h-[75px] flex items-center justify-center',
                  isSelectedMonth(monthDate) && 'bg-primary-600 dark:bg-primary-500',
                )}
                key={`${name}-month-${month}`}
                onPress={() => handleMonthClick(monthDate)}
                style={{ width: '33%' }}
              >
                <Text
                  className={cln(
                    'text-neutral-930 dark:text-neutral-50',
                    isSelectedMonth(monthDate) && 'text-neutral-50 dark:text-neutral-930',
                  )}
                >
                  {formatMonthPickerLabelText(monthDate, i18n.language)}
                </Text>
              </Pressable>
            );
          })}
        </View>
      );
    };

    const renderYears = () => {
      const yearTable = generateYearTable();

      return (
        <View className="flex-row flex-wrap justify-between" style={{ width: '100%' }}>
          {yearTable.map((year) => {
            return (
              <Pressable
                className={cln(
                  'cursor-pointer h-[101px] flex items-center justify-center',
                  year.isSelected && 'bg-primary-600 dark:bg-primary-500',
                )}
                key={`${name}-year-${year.year}`}
                onPress={() => handleYearClick(year.year)}
                style={{ width: '25%' }}
              >
                <Text
                  className={cln(
                    'text-neutral-930 dark:text-neutral-50',
                    year.isSelected && 'text-neutral-50 dark:text-neutral-930',
                  )}
                >
                  {year.year}
                </Text>
              </Pressable>
            );
          })}
        </View>
      );
    };

    const renderTodayButton = () => {
      return (
        <View className="flex justify-center items-center mb-3 mt-2">
          <Pressable
            className="border border-solid border-neutral-600 py-[12px] px-[12px] capitalize"
            onPress={handleSelectToday}
          >
            <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-label">
              {t('events:today')}
            </Text>
          </Pressable>
        </View>
      );
    };

    const renderTimePicker = () => {
      const timeTable = generateTimeTable();
      return (
        <>
          <TimePicker hours={timeTable} onClick={handleSelectTime} />
        </>
      );
    };

    const stepMonthBack = () => {
      const lastMonth = getLastMonth(shownDate, minDate);
      setShownDate(lastMonth);
    };

    const stepMonthForward = () => {
      const nextMonth = getNextMonth(shownDate, maxDate);
      setShownDate(nextMonth);
    };

    const stepYearBack = () => {
      const lastYear = getLastYear(shownDate, minDate, maxDate);
      setShownDate(lastYear);
    };

    const stepYearForward = () => {
      const newYear = getNextYear(shownDate, minDate, maxDate);
      setShownDate(newYear);
    };

    const stepDecadeBack = () => {
      const lastDecade = changeDate(shownDate, -10, minDate, maxDate);
      setShownDate(lastDecade);
    };

    const stepDecadeForward = () => {
      const nextDecade = changeDate(shownDate, 10, minDate, maxDate);
      setShownDate(nextDecade);
    };

    const renderMonthStepper = () => {
      return (
        <View className="w-full flex-row">
          <DatePickerStepper
            onClickBack={stepMonthBack}
            onClickForward={stepMonthForward}
            onClickLabel={handleMonthStepperClick}
            border
            label={formatMonthStepperLabelText(shownDate, i18n.language)}
          />
        </View>
      );
    };

    const renderYearStepper = () => {
      return (
        <View className="flex-row" style={{ width: '100%' }}>
          <DatePickerStepper
            onClickBack={stepYearBack}
            onClickForward={stepYearForward}
            onClickLabel={handleYearStepperClick}
            label={formatYearStepperLabelText(shownDate)}
          />
        </View>
      );
    };

    const renderDecadeStepper = () => {
      return (
        <View className="w-full flex-row">
          <DatePickerStepper
            onClickBack={stepDecadeBack}
            onClickForward={stepDecadeForward}
            label={formatDecadeStepperLabelText(shownDate)}
          />
        </View>
      );
    };

    const renderPicker = () => {
      return (
        <View className={cln('flex flex-row z-50')} ref={portalTo ? reference : null}>
          <View style={{ width: '100%' }}>{switchPicker()}</View>
          {pickerMode === 'day' && type === 'datetime-local' && renderTimePicker()}
        </View>
      );
    };

    const renderDayPicker = () => {
      return (
        <>
          {renderMonthStepper()}
          {renderDayNames()}
          {renderDays()}
          {renderTodayButton()}
        </>
      );
    };

    const renderMonthPicker = () => {
      return (
        <Text className="flex flex-col">
          {renderYearStepper()}
          {renderMonths()}
        </Text>
      );
    };

    const renderYearPicker = () => {
      return (
        <Text className="flex flex-col">
          {renderDecadeStepper()}
          {renderYears()}
        </Text>
      );
    };

    const switchPicker = () => {
      switch (pickerMode) {
        case 'day':
          return renderDayPicker();
        case 'month':
          return renderMonthPicker();
        case 'year':
          return renderYearPicker();
      }
    };

    return (
      <Pressable
        ref={!portalTo ? reference : null}
        className="select-none relative w-full"
        onPress={handleOnClick}
      >
        <View
          style={
            isModal && {
              display: visible ? 'flex' : 'none',
              width: 384,
              position: 'absolute',
              top: -400,
              left: '100%',
              alignSelf: 'center',
              zIndex: 300,
              paddingHorizontal: 20,
              paddingVertical: 12,
              borderWidth: 1,
            }
          }
          className="bg-neutral-50 dark:bg-neutral-950 border-neutral-930 dark:border-neutral-50"
        >
          {(visible || hideInput) && portalTo && createPortal(renderPicker(), portalTo)}
          {(visible || hideInput) && !portalTo && renderPicker()}
        </View>

        <DatePickerInput
          {...props}
          disabled={disabled}
          hidden={hideInput}
          value={inputValue}
          pickerVisible={visible}
          ref={inputRef}
          errorMessage={errorMessage}
        />
      </Pressable>
    );
  },
);

CustomDatePicker.displayName = 'CustomDatePicker';
export default CustomDatePicker;
