import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import { RouteProp } from '@react-navigation/native';
import { current } from '@react-native-community/cli-tools/build/releaseChecker';
import { NavigationParamList } from '../../navigation/types';

interface NavigationContextType {
  stackNavigation: NativeStackNavigationProp<NavigationParamList>;
  setStackNavigation: React.Dispatch<
    React.SetStateAction<NativeStackNavigationProp<NavigationParamList> | undefined>
  >;
  stackRoute: RouteProp<Record<string, object | undefined>>;
  setStackRoute: React.Dispatch<
    React.SetStateAction<RouteProp<Record<string, object | undefined>>>
  >;
  changedStack: string;
  setChangedStack: Dispatch<SetStateAction<string>>;
}

const NavigationContextStack = createContext<NavigationContextType | undefined>(undefined);

export const StackProvider = ({ children }) => {
  const [stackNavigation, setStackNavigation] =
    useState<NativeStackNavigationProp<NavigationParamList>>();
  const [stackRoute, setStackRoute] = useState<RouteProp<Record<string, object | undefined>>>();
  const [changedStack, setChangedStack] = useState(null);

  return (
    <NavigationContextStack.Provider
      value={{
        stackNavigation,
        setStackNavigation,
        stackRoute,
        setStackRoute,
        changedStack,
        setChangedStack,
      }}
    >
      {children}
    </NavigationContextStack.Provider>
  );
};

export { NavigationContextStack };
