import React from 'react';
import { Dimensions, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Button } from '../index';
import { isMobileWeb } from '../../utils/responsive';
import { useResize } from '../../utils/resize';
import { NavigationParamList, ScreenName } from '../../navigation/types';

interface Props {
  title: string;
  showAddNew: boolean;
  children: React.ReactNode;
}

const Section: React.FC<Props> = ({ title, showAddNew, children }) => {
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, ScreenName.AdminArticleEditScreen>>();
  const deviceWidth = Dimensions.get('window').width;
  const { colorScheme } = useColorScheme();
  const { isWidthBelowSmall } = useResize();

  const { t } = useTranslation();
  // LAYOUT CONTENT PADDING 40
  return (
    <div
      style={{
        overflow: 'visible',
        marginBottom: isMobileWeb ? 40 : 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: 0,
          marginBottom: 36,
          overflow: 'auto',
        }}
      >
        <Text
          style={{
            color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
            width: '50%',
            fontFamily: 'sans-600',
            fontSize: isWidthBelowSmall ? 36 : 48,
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          {title}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {showAddNew && deviceWidth > 1038 && (
            <Button
              text={t('section:add_new')}
              variant="outline"
              size="medium"
              style={{ marginBottom: 0 }}
              onPress={() => navigation.navigate(ScreenName.AdminArticleEditScreen)}
            />
          )}
        </div>
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
};

export default Section;
