import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Text, View } from 'react-native';
import i18n from 'i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BlurView } from 'expo-blur';
import { useColorScheme } from 'nativewind';
import { Layout, Loader, Favorites, Button, Icon } from '../../components';
import { useTags } from '../../contexts/Tag/TagContext';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import AnimatedText from '../../components/AnimatedText';
import {
  isMobile,
  isMobileWeb,
  isNative,
  isTablet,
  isWeb,
  isWidthBelowMedium,
  navHeight,
} from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import svgIcons from '../../assets';
import client from '../../client/client';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { SplashScreen } from '../index';
import { useMessage } from '../../contexts/Messages/MessageContext';
import LanguageChanger from '../../components/LanguageChanger';
import { Language } from '../../resources/interfaces';

const OnboardingScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.OnboardingScreen>
> = ({ navigation }) => {
  const { tags } = useTags();
  const { t } = useTranslation();
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchMe, me } = useAuth();
  const { setMessage } = useMessage();
  const insets = useSafeAreaInsets();
  const navOpacity = Platform.OS === 'android' ? 0.9 : 0.75;
  const { colorScheme } = useColorScheme();

  useEffect(() => {
    const setOnboarded = async () => {
      try {
        await client.setOnboarded();
        await fetchMe();
        await client.saveUserLanguage(i18n.language as Language).catch((error) => {
          console.error('save user language error:', error);
        });
      } catch (error) {
        console.log('error is', error?.response);
        if (error?.response?.status === 422) {
          console.log('User is already onboarded!');
        } else {
          setMessage({ message: error.message, type: 'error' });
          throw error;
        }
      }
    };

    if (me && !me?.isOnboarded) {
      setOnboarded();
    }
  }, [me]);

  const followRequiredTags = async () => {
    const requiredTagLinks = tags
      .filter((t) => t.isRequired)
      .map((t) => ({
        tagId: t.id,
        shouldNotify: true,
      }));

    if (requiredTagLinks.length > 0) {
      try {
        await client.createTag({
          tagLinks: requiredTagLinks,
        });
      } catch (error) {
        console.error('Following required tags was not successful', error);
      }
    }
  };

  const renderedContent = isAnimated ? (
    //   ...............
    //   ANIMATING SCREEN
    //   ................
    isLoading ? (
      <SplashScreen />
    ) : (
      <View className="bg-neutral-50 dark:bg-neutral-950 flex-1 justify-center">
        <BlurView
          style={{
            height: navHeight + insets.top,
            paddingHorizontal: isWidthBelowMedium && isNative ? 20 : 80,
            position: isNative ? 'absolute' : ('fixed' as 'absolute'),
            top: 0,
            borderBottomWidth: 1,
            alignItems: 'center',
            backgroundColor:
              colorScheme === 'dark'
                ? `rgba(16,16,16,${navOpacity})`
                : `rgba(246,246,246,${navOpacity})`,
          }}
          intensity={30}
          tint={colorScheme}
          className={cln(
            isMobile ? 'justify-end pb-5' : 'justify-center',
            'w-full z-50 border-neutral-200 dark:border-neutral-930',
          )}
        >
          <Icon
            icon={svgIcons.logo}
            mobileSize={{ width: 113, height: 26 }}
            webSize={{ width: 113, height: 26 }}
          />
        </BlurView>

        <AnimatedText />

        <View
          style={{
            paddingHorizontal: 30,
            position: isNative ? 'absolute' : ('fixed' as 'absolute'),
            bottom: isNative ? 40 : 80,
            width: '100%',
          }}
        >
          <Button
            text={t('onboarding:lets_go')}
            onPress={async () => {
              setIsLoading(true);
              navigation.navigate(ScreenName.HomeScreen);
            }}
            variant={'fill'}
            size={'medium'}
            style={{ alignSelf: !isMobile ? 'center' : isTablet ? 'center' : undefined }}
          />
        </View>
      </View>
    )
  ) : (
    //   ..................
    //   TAG CHOSING SCREEN
    //   ..................
    <View>
      <BlurView
        style={{
          height: navHeight + insets.top,
          paddingHorizontal: isWidthBelowMedium && isNative ? 20 : 80,
          position: isNative ? 'absolute' : ('fixed' as 'absolute'),
          top: 0,
          borderBottomWidth: 1,
          alignItems: 'center',
          backgroundColor:
            colorScheme === 'dark'
              ? `rgba(16,16,16,${navOpacity})`
              : `rgba(246,246,246,${navOpacity})`,
        }}
        intensity={30}
        tint={colorScheme}
        className={cln(
          isMobile ? 'justify-end pb-5' : 'justify-center',
          'w-full z-50 border-neutral-200 dark:border-neutral-930',
        )}
      >
        <Icon
          icon={svgIcons.logo}
          mobileSize={{ width: 113, height: 26 }}
          webSize={{ width: 113, height: 26 }}
        />
      </BlurView>
      <Layout
        layoutWithoutMenu={true}
        title={t('onboarding:onboarding_title')}
        isScrollView={isMobile}
      >
        <Text className="text-sectionSubtitle align-left w-full text-neutral-950 dark:text-neutral-50 mb-20 font-[sans-600] max-w-[800px]">
          {t('onboarding:onboarding_subtitle')}
        </Text>
        <View
          style={{
            maxWidth: 800,
            width: '100%',
            marginBottom: 40,
          }}
        >
          <LanguageChanger />
        </View>
        <Favorites />
        <View
          style={{
            flex: 1,
            width: '100%',
          }}
        >
          <Button
            text={t('onboarding:next')}
            onPress={async () => {
              setIsAnimated(true);
              await followRequiredTags();
            }}
            variant={'fill'}
            size={'medium'}
            style={{ alignSelf: !isMobile ? 'center' : isTablet ? 'center' : undefined }}
          />
        </View>
      </Layout>
    </View>
  );
  return tags ? renderedContent : <Loader />;
};

export default OnboardingScreen;
