import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import dayjs from 'dayjs';
import { EventCardList, Button } from '../../components';
import { Event, GetEventsParams, Order, PostOrderBy } from '../../client/interfaces';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { NavigationParamList, ScreenName } from '../../navigation/types';
import { isMobile, isNative } from '../../utils/responsive';

const emptyGetEventsParams: GetEventsParams = {
  page: 1,
  perPage: 99,
  search: '',
  tags: [],
  isBookmarked: false,
  date: dayjs().format('YYYY-MM-DD'),
};

const EventSection: React.FC = () => {
  const { setMessage } = useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const navigation = useNavigation<NavigationProp<NavigationParamList, ScreenName.EventsScreen>>();
  const { t } = useTranslation();
  const [eventsParams, setEventsParams] = useState(emptyGetEventsParams);

  const eventsParamsJSON = JSON.stringify(eventsParams);

  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const eventsResponse = await client.getEvents(eventsParams);
      setEvents(eventsResponse?.items || []);
      setIsLoading(false);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
    setIsLoading(false);
  };

  const emptyEventsState = () => {
    setEvents([]);
  };

  useFocusEffect(
    useCallback(() => {
      fetchEvents();

      return () => {
        emptyEventsState();
      };
    }, [eventsParamsJSON]),
  );

  const toggleBookmark = (event: Event) => {
    setEvents((prev) =>
      prev.map((a) => (a.id === event.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );
  };

  useEffect(() => {
    const subscribe = navigation.addListener('focus', () => {
      setEventsParams(emptyGetEventsParams);
      fetchEvents();
    });

    return subscribe;
  }, [navigation]);

  return (
    <View style={{ flex: isNative ? 1 : null }}>
      <EventCardList
        items={events}
        toggleBookmark={toggleBookmark}
        isEventSection={true}
        eventsParams={eventsParams}
        setEventsParams={setEventsParams}
        isLoading={isLoading}
      />
      <View
        style={{
          paddingHorizontal: isNative ? 32 : 0,
          width: '100%',
          marginTop: 24,
        }}
      >
        <Button
          text={t('section:other_events')}
          variant={'outline'}
          size={'medium'}
          onPress={() => navigation.navigate(ScreenName.EventsScreen, { isBookmarked: false })}
          style={{ alignItems: 'flex-end' }}
          iconName={!isMobile && 'arrowRightIcon'}
        />
      </View>
    </View>
  );
};

export default EventSection;
