import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { cln } from '../../utils/classnames';
import { isMobile } from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons, { SvgIcon } from '../../assets';

interface Props {
  onPress: () => void;
  title?: string;
  webSubtitle?: string;
  selectedIcon?: SvgIcon;
  isNavigation?: boolean;
}

const NavItem: React.FC<Props> = ({ onPress, selectedIcon, title, webSubtitle, isNavigation }) => {
  return isMobile || isNavigation ? (
    <View className="md:flex-row md:w-full flex-column w-full">
      <View className={cln(isMobile && 'w-full')}>
        <Pressable
          onPress={onPress}
          className={cln(
            'flex-row items-center justify-between py-[10px]',
            isMobile && 'border-b border-b-neutral-100 dark:border-b-[#525252]',
          )}
        >
          <Text
            className={cln(
              'text-neutral-930 dark:text-neutral-50 text-body font-[sans-400]',
              !isMobile && 'mr-3 text-sectionSubtitle font-[sans-700]',
            )}
          >
            {title}
          </Text>
          <Icon
            icon={svgIcons.arrowSimpleRightIcon}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 32, height: 32 }}
          />
        </Pressable>
      </View>
    </View>
  ) : (
    <View className="md:flex-row md:w-full flex-column w-full">
      <View className="md:flex-1 md:mr-10 gap-y-4 mb-10">
        <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50">
          {title}
        </Text>
        <View className="flex-row items-center">
          <Pressable onPress={onPress} className="flex-row items-center">
            <Icon
              icon={selectedIcon}
              webSize={{ width: 28, height: 28 }}
              mobileSize={{ width: 28, height: 28 }}
              classNames="mr-[8px] bottom-[1px]"
            />
            <Text className="text-xs md:text-body font-[sans-700] text-neutral-950 dark:text-neutral-50 underline">
              {webSubtitle}
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default NavItem;
