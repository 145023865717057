export const MONTH = {
  JAN: 0,
  FEB: 1,
  MARC: 2,
  APR: 3,
  MAY: 4,
  JUN: 5,
  JUL: 6,
  AUG: 7,
  SEP: 8,
  OCT: 9,
  NOV: 10,
  DEC: 11,
};

// TODO: ide kell egy dinamikusan nyelvesített megoldás
export const dayNames = {
  en: ['M', 'Tu', 'W', 'Th', 'F', 'S', 'Su'],
  hu: ['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'],
};

export const getTwoDigitString = (n: number): string => {
  if (n < 10) {
    return `0${n}`;
  }
  return n.toString();
};

export const formatToISODate = (dateToFormat: Date) => {
  const year = dateToFormat.getFullYear();
  const day = getTwoDigitString(dateToFormat.getDate());
  const month = getTwoDigitString(dateToFormat.getMonth() + 1);

  return `${year}-${month}-${day}`;
};

export const formatMonthPickerLabelText = (date: Date, locale = 'default'): string => {
  return date.toLocaleDateString(locale, { month: 'long' });
};

export const formatYearStepperLabelText = (date: Date): string => {
  return date.toLocaleDateString('default', { year: 'numeric' });
};

export const formatMonthStepperLabelText = (date: Date, locale = 'default'): string => {
  return date.toLocaleDateString(locale, {
    month: 'long',
    year: 'numeric',
  });
};

export const getStartOfDecade = (date: Date) => {
  const yearString = date.getFullYear().toString();
  const year = Number(yearString[0] + yearString[1] + yearString[2] + '0');
  return new Date(year, 1);
};

export const getEndOfDecade = (date: Date) => {
  const startOfDecade = getStartOfDecade(date);
  return new Date(startOfDecade.getFullYear() + 10, 1);
};

export const formatDecadeStepperLabelText = (date: Date): string => {
  const startOfDecade = getStartOfDecade(date);
  const startOfDecadeString = startOfDecade.toLocaleDateString('default', {
    year: 'numeric',
  });
  const endOfDecade = getEndOfDecade(date);
  const endOfDecadeString = endOfDecade.toLocaleDateString('default', {
    year: 'numeric',
  });
  return `${startOfDecadeString} - ${endOfDecadeString}`;
};

export const getFirstDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth());
};

export const getDaysOfMonth = (date: Date): number => {
  return 40 - new Date(date.getFullYear(), date.getMonth(), 40).getDate();
};

export const changeDate = (date: Date, n: number, minDate: Date, maxDate: Date): Date => {
  const currentYear = date.getFullYear();
  if (currentYear === minDate.getFullYear() || currentYear === maxDate.getFullYear()) {
    return date;
  }

  date.setFullYear(currentYear + n);

  return new Date(date.toISOString());
};

export const getLastMonth = (date: Date, minDate: Date): Date => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();

  const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;

  if (lastMonth === 11 && currentYear === minDate.getFullYear()) {
    return date;
  }

  return new Date(lastYear, lastMonth);
};

export const getNextMonth = (date: Date, maxDate: Date): Date => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();

  const year = currentMonth === 11 ? currentYear + 1 : currentYear;
  const month = currentMonth === 11 ? 0 : currentMonth + 1;

  if (month === 0 && currentYear === maxDate.getFullYear()) {
    return date;
  }

  return new Date(year, month);
};

export const getLastYear = (date: Date, minDate: Date, maxDate: Date): Date => {
  return changeDate(date, -1, minDate, maxDate);
};

export const getNextYear = (date: Date, minDate: Date, maxDate: Date): Date => {
  return changeDate(date, 1, minDate, maxDate);
};

export const isToday = (date: Date): boolean => {
  return formatToISODate(date) === formatToISODate(new Date());
};
