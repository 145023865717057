import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, DimensionValue, Platform, Pressable, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { isMobile, isNative, isTablet, isWeb } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { Icon, CalendarCard } from '../index';
import svgIcons from '../../assets';
import { CalendarEvent } from '../../client/interfaces';

interface Props {
  items: CalendarEvent[];
  position?: number;
}

const CalendarCardList: React.FC<Props> = ({ items, position }) => {
  const webContainer = useRef(null);
  const [moveValue, setMoveValue] = useState(0);
  const [windowWidth, setWindowWidth] = useState(null);
  // Arrow click counter
  const [counter, setCounter] = useState(1);
  const horizontalScrollViewRef = useRef(null);
  const [displayLeftArrow, setDisplayLeftArrow] = useState('none');
  const [displayRightArrow, setDisplayRightArrow] = useState('none');

  const firstShouldShowNowLineIndex = items?.findIndex((item) => item.shouldShowNowLine);

  // Card gap and arrow icon size
  const gapValue = 8;
  const iconSize = 24;

  // Breakpoints
  const bigBreakPoint = 1100;
  const midBreakPoint = 900;
  const smallBreakPoint = 550;

  useEffect(() => {
    const calculateMoveValue = () => {
      let baseMoveValue;
      if (windowWidth > bigBreakPoint) {
        baseMoveValue = webContainer.current?.offsetWidth / 4 + gapValue / 2;
      } else if (windowWidth < bigBreakPoint && windowWidth > midBreakPoint) {
        baseMoveValue = webContainer.current?.offsetWidth / 3 + gapValue / 2;
      } else if (windowWidth < midBreakPoint && windowWidth > smallBreakPoint) {
        baseMoveValue = webContainer.current?.offsetWidth / 2 + gapValue;
      } else if (windowWidth < smallBreakPoint) {
        baseMoveValue = webContainer.current?.offsetWidth + gapValue * 2;
      }

      return baseMoveValue * (position - 1) * -1;
    };

    if (position > 1) {
      setMoveValue(calculateMoveValue());
      setCounter(position);
    }
  }, [windowWidth, position]);

  // Show or hide arrows when moveValue and items change
  useEffect(() => {
    // LEFT ARROW
    setDisplayLeftArrow(counter > 1 ? 'flex' : 'none');

    // FOR RIGHT ARROW
    // Breakpoints
    const isSmallScreen = windowWidth < smallBreakPoint;
    const isMidScreen = windowWidth < midBreakPoint && windowWidth >= smallBreakPoint;
    const isBigScreen = windowWidth < bigBreakPoint && windowWidth >= midBreakPoint;
    const isExtraBigScreen = windowWidth >= bigBreakPoint;

    // ItemLengths
    const isSingleItemOrLess = items?.length <= 1;
    const isTwoItemsOrLess = items?.length <= 2;
    const isThreeItemsOrLess = items?.length <= 3;
    const isFourItemsOrLess = items?.length <= 4;

    // End of carousel
    const isCounterOverflowSmall = counter + 1 > items?.length;
    const isCounterOverflowMid = counter + 2 > items?.length;
    const isCounterOverflowBig = counter + 3 > items?.length;
    const isCounterOverflowExtraBig = counter + 4 > items?.length;

    const shouldDisplayNone =
      (isSmallScreen && (isSingleItemOrLess || (items?.length > 1 && isCounterOverflowSmall))) ||
      (isMidScreen && (isTwoItemsOrLess || (items?.length > 2 && isCounterOverflowMid))) ||
      (isBigScreen && (isThreeItemsOrLess || (items?.length > 3 && isCounterOverflowBig))) ||
      (isExtraBigScreen && (isFourItemsOrLess || (items?.length > 4 && isCounterOverflowExtraBig)));

    // Set display style
    const displayStyle = shouldDisplayNone ? 'none' : 'flex';
    setDisplayRightArrow(displayStyle);
  }, [moveValue, items, windowWidth]);

  // Updating the window width only on web
  useEffect(() => {
    setWindowWidth(Dimensions.get('window').width);
    function watchWidth() {
      setWindowWidth(Dimensions.get('window').width);
    }
    if (!isNative) {
      window.addEventListener('resize', watchWidth);
    }

    return function () {
      if (!isNative) {
        window.removeEventListener('resize', watchWidth);
      }
    };
  }, []);

  const visibleCards = isTablet ? 3 : 1;

  // Phone width of cards
  const cardWidth =
    items?.length > visibleCards
      ? Dimensions.get('window').width * 0.75
      : Dimensions.get('window').width * 0.85;

  // Tablet width of cards
  //To see 3 items at once
  const cardWidthTablet =
    items?.length > visibleCards
      ? Dimensions.get('window').width * 0.275
      : Dimensions.get('window').width * 0.3;

  // Scroll to position in native onLayout with scrollview
  const scrollToPosition = () => {
    if (horizontalScrollViewRef.current) {
      horizontalScrollViewRef.current.scrollTo({
        x:
          (isTablet && items.length > 3) || (!isTablet && items.length > 1)
            ? (isTablet ? cardWidthTablet : cardWidth + 8) * (position - 1) - 42
            : -24,
      });
    }
  };

  const cardWidthWeb =
    windowWidth < smallBreakPoint
      ? '100%'
      : windowWidth < midBreakPoint
        ? webContainer.current?.offsetWidth / 2 - gapValue
        : windowWidth < bigBreakPoint
          ? webContainer.current?.offsetWidth / 3 - gapValue / 0.67
          : windowWidth > bigBreakPoint
            ? webContainer.current?.offsetWidth / 4 - gapValue / 0.67
            : '100%';

  const renderedCards = items?.map((card, index) => {
    return (
      <CalendarCard
        key={index}
        styleProp={{
          marginHorizontal: isNative ? 4 : 0,
          width: isTablet
            ? cardWidthTablet
            : isNative
              ? cardWidth
              : (cardWidthWeb as DimensionValue),
          marginBottom: 0,
        }}
        item={card}
      />
    );
  });

  type Operation = 'increment' | 'decrement';

  const handlePress = (operation: Operation) => {
    setCounter((prevValue) => (operation === 'increment' ? prevValue + 1 : prevValue - 1));

    let offsetMultiplier;

    if (windowWidth > bigBreakPoint) {
      offsetMultiplier = webContainer.current?.offsetWidth / 4 + gapValue / 2;
    } else if (windowWidth < bigBreakPoint && windowWidth > midBreakPoint) {
      offsetMultiplier = webContainer.current?.offsetWidth / 3 + gapValue / 2;
    } else if (windowWidth < midBreakPoint && windowWidth > smallBreakPoint) {
      offsetMultiplier = webContainer.current?.offsetWidth / 2 + gapValue;
    } else if (windowWidth < smallBreakPoint) {
      offsetMultiplier = webContainer.current?.offsetWidth + gapValue * 2;
    }

    setMoveValue((prevState) =>
      operation === 'increment' ? prevState - offsetMultiplier : prevState + offsetMultiplier,
    );
  };

  return (
    <View
      className={cln(isMobile ? 'px-[0px]' : 'xl:px-0', 'justify-center items-start h-full flex-1')}
      style={{
        maxWidth: !isNative ? '100%' : null,
        // We need to check for both conditions, because isMobile also checks if we are on web on small screen
        width: isNative ? windowWidth : windowWidth > midBreakPoint ? windowWidth - 100 : '100%',
        paddingLeft: isNative ? 8 : 0,
      }}
    >
      {isNative ? (
        <View className="w-full">
          <ScrollView
            horizontal
            decelerationRate={0}
            automaticallyAdjustContentInsets={false}
            snapToInterval={isTablet ? cardWidthTablet + 8 : cardWidth + 8} // Card size plus both margins
            snapToAlignment="center"
            overScrollMode="never"
            scrollEnabled={isTablet ? items.length >= 4 : items.length >= 2}
            contentInset={{
              // iOS ONLY
              top: 0,
              left: items.length >= 2 ? 20 : 20, // Left spacing for the first card
              bottom: 0,
              right: 24, // Right spacing for the last card
            }}
            contentContainerStyle={{
              // contentInset for Android
              paddingHorizontal: Platform.OS === 'android' ? gapValue : 0, // Horizontal spacing before and after the ScrollView
              marginLeft: Platform.OS === 'android' && items.length === 1 ? 12 : 0,
            }}
            ref={horizontalScrollViewRef}
            onLayout={scrollToPosition}
            showsHorizontalScrollIndicator={false}
          >
            {renderedCards}
          </ScrollView>
        </View>
      ) : (
        //   WEB SCROLL CONTAINER
        <View className="w-full flex-row items-center justify-center" ref={webContainer}>
          {/*LEFT ICON*/}
          <Pressable
            className="z-[30] absolute justify-center items-end"
            style={[
              {
                width: iconSize,
                height: iconSize,
                left: -iconSize,
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb
                ? {
                    top: `calc(55% - ${iconSize / 2}px)`,
                    bottom: `calc(55% - ${iconSize / 2}px)`,
                    display: displayLeftArrow,
                  }
                : null,
            ]}
            onPress={() => handlePress('decrement')}
          >
            <Icon
              icon={svgIcons.arrowSimpleLeftIcon}
              mobileSize={{ width: iconSize, height: iconSize }}
              webSize={{ width: iconSize, height: iconSize }}
            />
            {/*SMALL DOT ON ARROW, if there is shouldShowNowLine*/}
            {firstShouldShowNowLineIndex >= 0 && counter - 1 > firstShouldShowNowLineIndex ? (
              <View
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  bottom: 4,
                  right: 4,
                  position: 'absolute',
                }}
                className="bg-primary-600 dark:bg-primary-500"
              />
            ) : (
              <></>
            )}
          </Pressable>

          <View className="w-full flex-1 overflow-hidden justify-start">
            <View
              className="flex-row scroll-smooth"
              style={[
                {
                  transform: `translateX(${moveValue}px)`,
                  rowGap: 64,
                  columnGap: 16,
                  // If it is todaySection we always need to noWrap, they never go in a second line
                  flexWrap: 'nowrap',
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isWeb ? { transition: 'transform 0.3s ease-in-out' } : null,
              ]}
            >
              {renderedCards}
            </View>
          </View>

          {/*RIGHT ARROW ICON BUTTON*/}
          <Pressable
            className="z-[30] absolute justify-center items-start"
            style={[
              {
                width: iconSize,
                height: iconSize,
                right: -iconSize,
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb
                ? {
                    top: `calc(55% - ${iconSize / 2}px)`,
                    bottom: `calc(55% - ${iconSize / 2}px)`,
                    display: displayRightArrow,
                  }
                : null,
            ]}
            onPress={() => handlePress('increment')}
          >
            <Icon
              icon={svgIcons.arrowSimpleRightIcon}
              mobileSize={{ width: iconSize, height: iconSize }}
              webSize={{ width: iconSize, height: iconSize }}
            />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default CalendarCardList;
